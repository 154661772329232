import React, { useState, useEffect } from 'react'
import './fullSheetRow.css'
import { FiAlertTriangle, FiChevronDown } from "react-icons/fi";
import { MdClose } from "react-icons/md";

import { changeFullSheetRowWithOpenSelection, changeFullSheetsRowConfig , getFullSheetAvailableThicknesses, selectedThicknessForFullSheetRow, changeFullSheetsInputCheck} from './orderConfig'

const FullSheetRow = React.memo(({ rowID, row, openMaterialSelection, openRemoveConfirmationPopup, fetchRowsConfig }) => {

    const [quantityInput, setQuantityInput] = useState('');
    const [inputAlerts, setInputAlerts] = useState(false);

    // refresh the input values when the component gets item changes (full sheet row config changed for this row)
    useEffect(() => {
        setQuantityInput(row?.quantity || '');;

        let qtyAlert = false;
        if(row?.quantity != null && row?.quantity <= 0){
            qtyAlert = true;
        }

        setInputAlerts(qtyAlert);

    }, [row]);

    const handleQtyChange = (event) =>{
        const value = event.target.value;
        if (row?.thickness != null) {
            const integerOnly = value.replace(/[^0-9]/g, '');

            changeFullSheetsRowConfig(rowID, integerOnly);
            setQuantityInput(integerOnly);
            setInputAlerts(integerOnly > 0 ? false : true);
            if(integerOnly <= 0){
                changeFullSheetsInputCheck(rowID, false);
            }else{
                changeFullSheetsInputCheck(rowID, true);
            }
            fetchRowsConfig();
        }
    }

    const handleOpenMaterialSelect = () => {
        console.log('Opening material select for row:' + rowID);
        openMaterialSelection(rowID);
    };

    //  Thickness dropdown handlers
    const [thicknessDropdownOpen, setThicknessDropdownOpen] = useState(false);
    const [thicknessDropdownOptions, setThicknessDropdownOptions] = useState([]);
    const toggleThicknessDropdown = () =>{
        if(row?.productCode !== null){
            setThicknessDropdownOpen(!thicknessDropdownOpen);
            if(!thicknessDropdownOpen == true){
                loadThicknessDropdownOptions();
            }
        }
    }
    const closeThicknessDropdown = () =>{
        setThicknessDropdownOpen(false);
    }
    const loadThicknessDropdownOptions = () =>{
        changeFullSheetRowWithOpenSelection(rowID);
        const thicknesses = getFullSheetAvailableThicknesses();
        setThicknessDropdownOptions(thicknesses);
    }
    const handleThicknessChange = (thickness) => {
        selectedThicknessForFullSheetRow(thickness);
        closeThicknessDropdown();
        fetchRowsConfig();
    }

    const [isTablet, setIsTablet] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Function to update the state based on window width
        const handleResize = () => {
            setIsTablet(window.innerWidth > 768 && window.innerWidth < 1220);
            setIsMobile(window.innerWidth <= 768);
        };

        // Initial check
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className='fullSheetsListRow'>
            {isTablet === true ? (
                <div className='fullSheetsListRowLabels'>
                    <div className='grid_item'><p>#</p></div>
                    <div className='grid_item'><p>Material decor code / name</p></div>
                    <div className='grid_item'><p>Thick [mm]</p></div>
                    <div className='grid_item'><p>Length [mm]</p></div>
                    <div className='grid_item'><p>Width [mm]</p></div>
                    <div className='grid_item'><p>Qty</p></div>
                    <div className='grid_item'><p>Brand</p></div>
                    <div className='grid_item' style={{ alignItems: 'end', paddingRight: '10px' }}><p>Actions</p></div>
                </div>
            ) : null}
            {isMobile === true ? (
                <div className='fullSheetsListRowInputs-mobile'>
                    <div className='fullSheetsRowInputsInline-mobile'>
                        <div className='grid_item'><p>{rowID + 1}.</p></div>
                        <div className='grid_item-mobile'>
                            <div className='rowBtn sameHeightBtn' style={{cursor: 'pointer'}} onClick={() => handleOpenMaterialSelect()}>
                                <p>{row?.productCode !== null ? row?.productCode : 'Select decor'}</p>
                            </div>
                        </div>
                    </div>
                    <div className='fullSheetsRowInputsInline-mobile'>
                        <div className='grid_item'><p>Thickness:</p></div>
                        <div className='thicknessDropdown-mobile'>
                            <div className='thicknessDropdownTop-mobile sameHeightBtn' style={{cursor: 'pointer'}} onClick={() => toggleThicknessDropdown()}>
                                <div className='thicknessDropdownTopValue-mobile'>
                                    <p>{row?.thickness}</p>
                                </div>
                                <FiChevronDown />
                            </div>
                            <div className='thicknessDropdownMenu' style={{display: thicknessDropdownOpen === true ? 'flex' : 'none'}}>
                                {thicknessDropdownOptions.map((thickness, index) => (
                                    <div key={index} className='thicknessDropdownMenuItem-mobile' onClick={() => handleThicknessChange(thickness)}><p>{thickness}</p></div>
                                ))}
                            </div>
                        </div>
                    </div>   
                    <div className='cuttingListRowInline-mobile'>
                        <p className='cuttingListRowInlineLabel-mobile'>Length:</p>
                        <p className='cuttingListRowInlineLabel-mobile'>{row?.materialLength != null ? row?.materialLength : '-'} mm</p>
                    </div>   
                    <div className='cuttingListRowInline-mobile'>
                        <p className='cuttingListRowInlineLabel-mobile'>Width:</p>
                        <p className='cuttingListRowInlineLabel-mobile'>{row?.width != null ? row?.width : '-'} mm</p>
                    </div>  
                    <div className='cuttingListRowInline-mobile'>
                        <p className='cuttingListRowInlineLabel-mobile'>Quantity:</p>
                        <div className='grid_item-mobile'>
                            <input className='inputField-mobile sameHeightBtn' value={quantityInput} onChange={handleQtyChange} disabled={row?.thickness != null ? false : true} style={{borderColor: inputAlerts === true ? '#fb0429' : '#ccc'}}></input>
                            <div className='rowInputAlert-mobile' style={{display: inputAlerts=== true ? 'flex' : 'none'}}>
                                <FiAlertTriangle className='rowInputAlertIcon'/>
                                <p className='rowInputAlertText'>Minimum quantity is 1</p>
                            </div>
                        </div>
                    </div>
                    <div className='cuttingListRowInline-mobile'>
                        <p className='cuttingListRowInlineLabel-mobile'>Brand:</p>
                        <p className='cuttingListRowInlineLabel-mobile'>{row?.brand != null ? row?.brand : '-'}</p>
                    </div>  
                    <div className='cuttingListActionBtnContainer-mobile'>
                        <div className='cuttingListDeleteRowBtn-tablet' onClick={() => openRemoveConfirmationPopup(rowID)}>
                            <MdClose style={{fontSize: '18px'}}/>
                            <p>Delete row</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='fullSheetsListRowInputs'>
                    <div className='grid_item'><p>{rowID + 1}</p></div>
                    <div className='grid_item'>
                        <div className='rowBtn' style={{cursor: 'pointer'}} onClick={() => handleOpenMaterialSelect()}>
                            <p>{row?.productCode !== null ? row?.productCode : 'Select decor'}</p>
                        </div>
                    </div>
                    <div className='grid_item'>
                    <div className='thicknessDropdown'>
                        <div className='thicknessDropdownTop' style={{cursor: 'pointer'}} onClick={() => toggleThicknessDropdown()}>
                                <div className='thicknessDropdownTopValue'>
                                    <p>{row?.thickness}</p>
                                </div>
                                <FiChevronDown />
                            </div>
                            <div className='thicknessDropdownMenu' style={{display: thicknessDropdownOpen === true ? 'flex' : 'none'}}>
                                {thicknessDropdownOptions.map((thickness, index) => (
                                    <div key={index} className='thicknessDropdownMenuItem' onClick={() => handleThicknessChange(thickness)}><p>{thickness}</p></div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='grid_item'>
                        <p>{row?.materialLength != null ? row?.materialLength : '-'}</p>
                    </div>
                    <div className='grid_item'>
                        <p>{row?.width != null ? row?.width : '-'}</p>
                    </div>
                    <div className='grid_item'>
                        <input className='inputField' value={quantityInput} onChange={handleQtyChange} disabled={row?.thickness != null ? false : true} style={{borderColor: inputAlerts === true ? '#fb0429' : '#ccc'}}></input>
                        <div className='rowInputAlert' style={{display: inputAlerts=== true ? 'flex' : 'none'}}>
                            <FiAlertTriangle className='rowInputAlertIcon'/>
                            <p className='rowInputAlertText'>Minimum quantity is 1</p>
                        </div>
                    </div>
                    <div className='grid_item'>
                        <p>{row?.brand != null ? row?.brand : '-'}</p>
                    </div>
                    <div className='grid_item'>
                        <div className='fullSheetActions'>
                            <MdClose style={{color: '#fb0429', scale: '1.7', cursor: 'pointer'}}
                                onClick={() => openRemoveConfirmationPopup(rowID)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
});

export default FullSheetRow