import React, { useState, useEffect } from 'react';
import './edgetapeRow.css';
import { FiAlertTriangle, FiChevronDown } from "react-icons/fi";
import { MdClose } from "react-icons/md";

import { setEdgingTapeRowWithOpenSelection, changeEdgingTapeInputCheck, changeEdgingTapeRowConfig, getAvailableEdgingTape, selectedEdgingTapeForRow } from './orderConfig';

const EdgetapeRow = React.memo(({ rowID, row, updateRowConfig, removeRow }) => {
    const [quantityInput, setQuantityInput] = useState('');
    const [inputAlerts, setInputAlerts] = useState(null);

    // refresh the input values when the component gets item changes (full sheet row config changed for this row)
    useEffect(() => {
        setQuantityInput(row?.quantity || '');

        let qtyAlert = false;
        if (row?.quantity != null && row?.quantity <= 0) {
            qtyAlert = true;
        }

        setInputAlerts(qtyAlert);

    }, [row]);

    const handleQtyChange = async (value) => {
        if (row?.name != null) {
            const integerOnly = value.replace(/[^0-9]/g, '');

            changeEdgingTapeRowConfig(rowID, integerOnly);
            setQuantityInput(integerOnly);
            setInputAlerts(integerOnly > 0 ? false : true);
            if (integerOnly <= 0) {
                await changeEdgingTapeInputCheck(rowID, false);
            } else {
                await changeEdgingTapeInputCheck(rowID, true);
            }
            updateRowConfig();
        }
    }

    const handleOpenEdgetapeRemove = () => {
        console.log('opening edgetape remove popup for row:' + rowID);
        removeRow(rowID);
    }

    //  Select edgetape dropdown handlers
    const [edgetapeDropdownOpen, setEdgetapeDropdownOpen] = useState(false);
    const [edgetapeDropdownOptions, setEdgetapeDropdownOptions] = useState([]);
    const toggleEdgetapeDropdown = async() => {
        setEdgetapeDropdownOpen(!edgetapeDropdownOpen); // Fixed the variable name
        if (!edgetapeDropdownOpen) { // Fixed the condition
            setEdgingTapeRowWithOpenSelection(rowID);
            const edgetapeOptions = await getAvailableEdgingTape(); // Removed unnecessary spread operator
            setEdgetapeDropdownOptions([...edgetapeOptions]);
            if (edgetapeOptions.length === 0) {
                setEdgetapeDropdownOptions([{
                    name: 'No options available'
                }]); // Set an empty array
            }
        }
    }
    const closeEdgetapeDropdown = () => {
        setEdgetapeDropdownOpen(false);
    }
    const handleEdgetapeChange = (edgetape) => { // Fixed the parameter name
        selectedEdgingTapeForRow(edgetape); // Fixed the function call
        closeEdgetapeDropdown();
        updateRowConfig();
    }

    const [isTablet, setIsTablet] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Function to update the state based on window width
        const handleResize = () => {
            setIsTablet(window.innerWidth > 768 && window.innerWidth < 1220);
            setIsMobile(window.innerWidth <= 768);
        };

        // Initial check
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className='fullSheetsListRow'>
            {isMobile === true ? (
                <div className='edgetapeListRowInputs-mobile'>
                    <div className='fullSheetsRowInputsInline-mobile'>
                        <div className='grid_item'><p>{rowID + 1}.</p></div>
                        <div className='grid_item-mobile'>
                            <div className='thicknessDropdown'>
                                <div className='thicknessDropdownTop sameHeightBtn' style={{ cursor: 'pointer' }} onClick={() => toggleEdgetapeDropdown()}>
                                    <div className='thicknessDropdownTopValue'>
                                        <p>{row?.name !== null ? `${row?.name} - ${row?.description}` : 'Select edging tape'}</p>
                                    </div>
                                    <FiChevronDown />
                                </div>
                                <div className='thicknessDropdownMenu' style={{ display: edgetapeDropdownOpen ? 'flex' : 'none' }}>
                                    {edgetapeDropdownOptions.map((edgetape, index) => (
                                        <div key={index} className='thicknessDropdownMenuItem' onClick={() => {
                                            if (edgetape.name === 'No options available') {
                                                closeEdgetapeDropdown();
                                            } else {
                                                handleEdgetapeChange(edgetape);
                                            }
                                        }}>
                                            {edgetape.name === 'No options available' ? (
                                                <p>No options available</p>
                                            ) : (
                                                <>
                                                    <p>{edgetape.name} / {edgetape.decor_name} / {edgetape.size} mm</p>
                                                    <p>{edgetape.description}</p> 
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cuttingListRowInline-mobile'>
                        <p className='cuttingListRowInlineLabel-mobile'>For material:</p>
                        <p className='cuttingListRowInlineLabel-mobile'>{row?.decor_name != null ? row?.decor_name : '-'}</p>
                    </div>  
                    <div className='cuttingListRowInline-mobile'>
                        <p className='cuttingListRowInlineLabel-mobile'>Size:</p>
                        <p className='cuttingListRowInlineLabel-mobile'>{row?.size != null ? row?.size : '-'} mm</p>
                    </div>  
                    <div className='cuttingListRowInline-mobile'>
                        <p className='cuttingListRowInlineLabel-mobile'>Quantity:</p>
                        <div className='grid_item-mobile'>
                            <input className='inputField-mobile sameHeightBtn' value={quantityInput} onChange={(e) => handleQtyChange(e.target.value)} disabled={row?.name != null ? false : true} style={{ borderColor: inputAlerts === true ? '#fb0429' : '#ccc', textAlign: 'center' }}></input>
                            <div className='rowInputAlert-mobile' style={{ display: inputAlerts === true ? 'flex' : 'none' }}>
                                <FiAlertTriangle className='rowInputAlertIcon' />
                                <p className='rowInputAlertText'>Minimum quantity is 1</p>
                            </div>
                        </div>
                    </div>
                    <div className='cuttingListActionBtnContainer-mobile'>
                        <div className='cuttingListDeleteRowBtn-tablet' onClick={handleOpenEdgetapeRemove}>
                            <MdClose style={{fontSize: '18px'}}/>
                            <p>Delete row</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='edgetapeListRowInputs'>
                    <div className='grid_item'><p>{rowID + 1}</p></div>
                    <div className='grid_item'>
                        <div className='thicknessDropdown'>
                            <div className='thicknessDropdownTop' style={{ cursor: 'pointer' }} onClick={() => toggleEdgetapeDropdown()}>
                                <div className='thicknessDropdownTopValue'>
                                    <p>{row?.name !== null ? `${row?.name} - ${row?.description}` : 'Select edging tape'}</p>
                                </div>
                                <FiChevronDown />
                            </div>
                            <div className='thicknessDropdownMenu' style={{ display: edgetapeDropdownOpen ? 'flex' : 'none' }}>
                                {edgetapeDropdownOptions.map((edgetape, index) => (
                                    <div key={index} className='thicknessDropdownMenuItem' onClick={() => {
                                        if (edgetape.name === 'No options available') {
                                            closeEdgetapeDropdown();
                                        } else {
                                            handleEdgetapeChange(edgetape);
                                        }
                                    }}>
                                        {edgetape.name === 'No options available' ? (
                                            <p>No options available</p>
                                        ) : (
                                            <>
                                                <p>{edgetape.name} / {edgetape.decor_name} / {edgetape.size} mm</p>
                                                <p>{edgetape.description}</p> 
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='grid_item'>
                        <p>{row?.decor_name != null ? row?.decor_name : ''}</p>
                    </div>
                    <div className='grid_item'>
                        <p>{row?.size != null ? row?.size : ''}</p>
                    </div>
                    <div className='grid_item'>
                        <input className='inputField' value={quantityInput} onChange={(e) => handleQtyChange(e.target.value)} disabled={row?.name != null ? false : true} style={{ borderColor: inputAlerts === true ? '#fb0429' : '#ccc', textAlign: 'center' }}></input>
                        <div className='rowInputAlert' style={{ display: inputAlerts === true ? 'flex' : 'none' }}>
                            <FiAlertTriangle className='rowInputAlertIcon' />
                            <p className='rowInputAlertText'>Minimum quantity is 1</p>
                        </div>
                    </div>
                    <div className='grid_item'>
                        <div className='fullSheetActions'>
                            <MdClose style={{ color: '#fb0429', scale: '1.7', cursor: 'pointer' }}
                                onClick={handleOpenEdgetapeRemove}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
});

export default EdgetapeRow;
