import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import './panelSummary.css'
import { FiX } from "react-icons/fi";
import { getRowConfig, getRowWithOpenSelection, getSelectedMaterial } from '../../orderConfig';

function PanelSummary(props) {

    const [isTablet, setIsTablet] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Function to update the state based on window width
        const handleResize = () => {
            setIsTablet(window.innerWidth > 768 && window.innerWidth < 1220);
            setIsMobile(window.innerWidth < 768);
        };

        // Initial check
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleContainerClick = (e) => {
        // Check if the click occurred outside the container
        if (e.target.classList.contains('summaryPopup')) {
            closePopup(); // Close the popup
        }
    };
    const closePopup = () =>{
        props.closeSummaryPopup();
    }

    const [rowConfig, setRowConfig] = useState(null);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    useEffect(() => {
        const currRow = getRowWithOpenSelection();
        const config = getRowConfig(currRow);
        setRowConfig(config);

        const materialDetails = getSelectedMaterial(currRow);
        setSelectedMaterial(materialDetails);
    }, []);
    const drawingPadding = 20;
    // the row machining config
    const [machiningConfig, setMachiningConfig] = useState({
        topLeft: null,
        topRight: null,
        bottomRight: null,
        bottomLeft: null,
        L1P: null,
        L2P: null,
        W1P: null,
        W2P: null,
        l1w1_edgebanding: null,
        l1w2_edgebanding: null,
        l2w1_edgebanding: null,
        l2w2_edgebadning: null,
        hingeHoles: null,
        hingeHolesPos: [],
        hingeHolesSide: null,
        hingeHolesPosLimits: null,
        hingeHolesSpacing: null,
        hingesType: null,
        clusters: null,
        clustersType: null,
        clustersPos: [],
        clustersStep: null,
        clustersPositions: null,
        clustersEdge: null,
        clustersFace: null,
        clustersDistance1: null,
        clustersDistance2: null,
        clustersPosLimits: null,
        shelfDrillingInputsLimits: null
    });
    useEffect(() => {
        if (rowConfig) {
            if (rowConfig.machiningConfig == null) {
                const obj = {
                    topLeft: null,
                    topRight: null,
                    bottomRight: null,
                    bottomLeft: null,
                    L1P: null,
                    L2P: null,
                    W1P: null,
                    W2P: null,
                    l1w1_edgebanding: null,
                    l1w2_edgebanding: null,
                    l2w1_edgebanding: null,
                    l2w2_edgebadning: null,
                    hingeHoles: null,
                    hingeHolesPos: [],
                    hingeHolesSide: null,
                    hingeHolesPosLimits: null,
                    hingeHolesSpacing: null,
                    hingesType: null,
                    clusters: null,
                    clustersType: null,
                    clustersPos: [],
                    clustersStep: null,
                    clustersPositions: null,
                    clustersEdge: null,
                    clustersFace: null,
                    clustersDistance1: null,
                    clustersDistance2: null,
                    clustersPosLimits: null,
                    shelfDrillingInputsLimits: null
                }
                setMachiningConfig(obj);
            } else {
                setMachiningConfig(rowConfig.machiningConfig);
            }
        }
    }, [rowConfig]);
    // drawing props are used only to store the display drawing data (scaled)
    const [drawingProps, setDrawingProps] = useState({
        drawingLength: null,
        drawingWidth: null,
        topLeft: null,
        topRight: null,
        bottomRight: null,
        bottomLeft: null,
        L1P: null,
        L2P: null,
        W1P: null,
        W2P: null,
        l1w1_edgebanding: null,
        l1w2_edgebanding: null,
        l2w1_edgebanding: null,
        l2w2_edgebadning: null,
        points: null,
        hingeHoles: [],
        clustersHoles: [],
        clusterStep: null,
        l1w1_arrows: {from_w2: null, from_l2: null},
        l1w2_arrows: {from_w1: null, from_l2: null},
        l2w1_arrows: {from_w2: null, from_l1: null},
        l2w2_arrows: {from_w1: null, from_l1: null},
    });

    const machiningPopupDrawingRef = useRef(null);
    const [viewDimensions, setViewDimensions] = useState({viewWidth: 0, viewHeight: 0});
    const [svgDimensions, setSvgDimensions] = useState({svgWidth: 0, svgHeight: 0});
    // get the middleSection width and height in px
    useLayoutEffect(() => {
        const element = machiningPopupDrawingRef.current;
        if (element) {
            // Get the size and position of the element
            const { width, height, top, left } = element.getBoundingClientRect();
            setViewDimensions(() => ({
                viewWidth: width,
                viewHeight: height,
            }));

            if(width != 0 && height != 0){
                const currRow = getRowWithOpenSelection();
                const currRowConfig = getRowConfig(currRow );
                displayInitialPanelDrawing(currRowConfig, width, height);
            }
            
            console.log('Middle section width:' + width);
            console.log('Middle section height:' + height);
        }
    }, []);


    // draw the drawing again when the cut props change
    useEffect(() => {
        if(viewDimensions.viewHeight != 0 && viewDimensions.viewWidth != 0){
            displayPanelDrawing();
        }
    }, [machiningConfig]);

    const displayInitialPanelDrawing = (currRowConfig, width, height) =>{
        console.log('-> /// DISPLAY INITIAL DRAWING');
        let inputLength = parseInt(currRowConfig.materialLength, 10);
        let inputWidth = parseInt(currRowConfig.width, 10);
        // check if there is an existing machiningConfig in the rowConfig
        if(currRowConfig.machiningConfig != null){
            const existingMachiningConfig = currRowConfig.machiningConfig;
            let drawingLength = null;
            let drawingWidth = null;
            let l1w1_arrows = null;
            let l1w2_arrows = null;
            let l2w1_arrows = null;
            let l2w2_arrows = null;
            let newSvgWidth = null;
            let newSvgHeight = null;
            let hingeScalePercentage = null;
            // calculate and set the svg dimensions and angle cut arrows
            if(inputLength >= inputWidth){
                // calculate the percentage of width out of length
                let percentage = (inputWidth / inputLength);
                newSvgWidth = width;
                if(percentage < 0.25){
                    percentage = 0.25;
                }
                newSvgHeight = width * percentage;
                if(newSvgHeight > height){
                    newSvgHeight = height;
                }
                setSvgDimensions(() => ({
                    svgWidth: newSvgWidth,
                    svgHeight: newSvgHeight,
                }));
                drawingLength = newSvgWidth - drawingPadding * 2;
                drawingWidth = newSvgHeight - drawingPadding * 2;
                // write the points string for the "from X" arrows
                l1w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                    from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                };
                l1w2_arrows = {
                    from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                    from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                };
                l2w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                };
                l2w2_arrows = {
                    from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                };
            }else{
                // calculate the percentage of length out of width
                let percentage = (inputLength / inputWidth);
                newSvgHeight = height;
                if(percentage < 0.25){
                    percentage = 0.25;
                }
                newSvgWidth = height * percentage;
                if(newSvgWidth > width){
                    newSvgWidth = width;
                }
                setSvgDimensions(() => ({
                    svgWidth: newSvgWidth,
                    svgHeight: newSvgHeight,
                }));
                drawingLength = newSvgWidth - drawingPadding * 2;
                drawingWidth = newSvgHeight - drawingPadding * 2;
                // write the points string for the "from X" arrows
                l1w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                    from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                };
                l1w2_arrows = {
                    from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                    from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                };
                l2w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                };
                l2w2_arrows = {
                    from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                };
            }
            //  check for angle cuts and transform every point from machiningConfig to drawingProps points
            //  points positions for the drawing display (scaled)
            const lengthScale = drawingLength / inputLength;
            const widthScale = drawingWidth / inputWidth;
            let topLeft = { x: drawingPadding + parseInt(existingMachiningConfig.topLeft.x) * lengthScale, y: drawingPadding};
            let topRight = { x: drawingPadding + drawingLength, y: drawingPadding + parseInt(existingMachiningConfig.topRight.y) * widthScale };
            let bottomRight = { x: drawingPadding + parseInt(existingMachiningConfig.bottomRight.x) * lengthScale, y: drawingPadding + drawingWidth};
            let bottomLeft = { x: drawingPadding, y: drawingPadding + parseInt(existingMachiningConfig.bottomLeft.y) * widthScale};
            let pointsString = '';
            let l1w1EdgebandingString = null;
            let l1w2EdgebandingString = null;
            let l2w1EdgebandingString = null;
            let l2w2EdgebandingString = null;
            let W1P = null;
            let W2P = null;
            let L1P = null;
            let L2P = null;
            // check for l1w1 cut
            if(existingMachiningConfig.W1P != null){
                const fW1P = existingMachiningConfig.W1P;
                W1P = {x: drawingPadding, y: parseInt(fW1P.y) * widthScale + drawingPadding};
                let W1P_points = `${W1P.x},${W1P.y}`;
                let TL = topLeft;
                let TL_points = `${TL.x},${TL.y}`;
                pointsString = `${W1P_points} ${TL_points}`;
                if(existingMachiningConfig.l1w1_edgebanding != null){
                    l1w1EdgebandingString = `M${W1P.x - 2.5} ${W1P.y - 2.5} L${TL.x - 2.5} ${TL.y - 2.5}`;
                }
            }else{
                let TL = topLeft;
                let TL_points = `${TL.x},${TL.y}`;
                pointsString = `${TL_points}`;
            }
            // check for l1w2 cut
            if(existingMachiningConfig.L1P != null){
                const fL1P = existingMachiningConfig.L1P;
                L1P = {x: parseInt(fL1P.x) * lengthScale + drawingPadding, y: drawingPadding};
                let L1P_points = `${L1P.x},${L1P.y}`;
                let TR = topRight;
                let TR_points = `${TR.x},${TR.y}`;
                pointsString = `${pointsString} ${L1P_points} ${TR_points}`;
                if(existingMachiningConfig.l1w2_edgebanding != null){
                    l1w2EdgebandingString = `M${L1P.x + 2.5} ${L1P.y - 2.5} L${TR.x + 2.5} ${TR.y - 2.5}`;
                }
            }else{
                let TR = topRight;
                let TR_points = `${TR.x},${TR.y}`;
                pointsString = `${pointsString} ${pointsString} ${TR_points}`;
            }
            // check for l2w2 cut
            if(existingMachiningConfig.W2P != null){
                const fW2P = existingMachiningConfig.W2P;
                W2P = {x: topRight.x, y: parseInt(fW2P.y) * widthScale + drawingPadding};
                let W2P_points = `${W2P.x},${W2P.y}`;
                let BR = bottomRight;
                let BR_points = `${BR.x},${BR.y}`;
                pointsString = `${pointsString} ${W2P_points} ${BR_points}`;
                if(existingMachiningConfig.l2w2_edgebanding != null){
                    l2w2EdgebandingString = `M${W2P.x + 2.5} ${W2P.y + 2.5} L${BR.x + 2.5} ${BR.y + 2.5}`;
                }
            }else{
                let BR = bottomRight;
                let BR_points = `${BR.x},${BR.y}`;
                pointsString = `${pointsString} ${BR_points}`;
            }
            // check for l2w1 cut
            if(existingMachiningConfig.L2P != null){
                const fL2P = existingMachiningConfig.L2P;
                L2P = {x: parseInt(fL2P.x) * lengthScale + drawingPadding, y: bottomRight.y};
                let L2P_points = `${L2P.x},${L2P.y}`;
                let BL= bottomLeft;
                let BL_points = `${BL.x},${BL.y}`;
                pointsString = `${pointsString} ${L2P_points} ${BL_points}`;
                if(existingMachiningConfig.l2w1_edgebanding != null){
                    l2w1EdgebandingString = `M${L2P.x - 2.5} ${L2P.y + 2.5} L${BL.x - 2.5} ${BL.y + 2.5}`;
                }
            }else{
                let BL= bottomLeft;
                let BL_points = `${BL.x},${BL.y}`;
                pointsString = `${pointsString} ${BL_points}`;
            }

            //  check for hinge drilling
            let scaledHingeHolesPos = [];
            if(existingMachiningConfig.hingeHoles != null){
                console.log('-> found hinge drilling');
                if(existingMachiningConfig.hingeHolesSide == 'l1'){
                    // add each hole to the drawing props (scaled)
                    const scalePercentage = lengthScale;
                    let hingeHolesPosInputs = [];
                    let hingeHolesPosInputsLimits = [];
                    let hingeHolesPosInputsErrors = [];
                    for(let i = 0; i < parseInt(existingMachiningConfig.hingeHoles); i++){
                        let scaledHingeHole = {
                            x: parseInt(existingMachiningConfig.hingeHolesPos[i].x) * scalePercentage + drawingPadding,
                            y: parseInt(existingMachiningConfig.hingeHolesPos[i].y) * scalePercentage + drawingPadding,
                        } 
                        scaledHingeHolesPos = [...scaledHingeHolesPos, scaledHingeHole];
                        //  set the hinge holes pos inputs values based on their current position
                        hingeHolesPosInputs = [...hingeHolesPosInputs, existingMachiningConfig.hingeHolesPos[i].x];
                        hingeHolesPosInputsErrors = [...hingeHolesPosInputsErrors, false];
                        //  set the hinge holes pos limits from rowConfig
                        hingeHolesPosInputsLimits = existingMachiningConfig.hingeHolesPosLimits;
                    }
                }else if(existingMachiningConfig.hingeHolesSide == 'l2'){
                    // add each hole to the drawing props (scaled)
                    const scalePercentage = lengthScale;
                    let hingeHolesPosInputs = [];
                    let hingeHolesPosInputsLimits = [];
                    let hingeHolesPosInputsErrors = [];
                    for(let i = 0; i < parseInt(existingMachiningConfig.hingeHoles); i++){
                        let scaledHingeHole = {
                            x: parseInt(existingMachiningConfig.hingeHolesPos[i].x) * scalePercentage + drawingPadding,
                            y: drawingWidth + drawingPadding - (50 * scalePercentage),
                        } 
                        scaledHingeHolesPos = [...scaledHingeHolesPos, scaledHingeHole];
                        //  set the hinge holes pos inputs values based on their current position
                        hingeHolesPosInputs = [...hingeHolesPosInputs, existingMachiningConfig.hingeHolesPos[i].x];
                        hingeHolesPosInputsErrors = [...hingeHolesPosInputsErrors, false];
                        //  set the hinge holes pos limits from rowConfig
                        hingeHolesPosInputsLimits = existingMachiningConfig.hingeHolesPosLimits;
                    }
                }else if(existingMachiningConfig.hingeHolesSide == 'w1'){
                    // add each hole to the drawing props (scaled)
                    const scalePercentage = widthScale;
                    let hingeHolesPosInputs = [];
                    let hingeHolesPosInputsLimits = [];
                    let hingeHolesPosInputsErrors = [];
                    for(let i = 0; i < parseInt(existingMachiningConfig.hingeHoles); i++){
                        let scaledHingeHole = {
                            x: parseInt(existingMachiningConfig.hingeHolesPos[i].x) * scalePercentage + drawingPadding,
                            y: parseInt(existingMachiningConfig.hingeHolesPos[i].y) * scalePercentage + drawingPadding,
                        } 
                        scaledHingeHolesPos = [...scaledHingeHolesPos, scaledHingeHole];
                        //  set the hinge holes pos inputs values based on their current position
                        hingeHolesPosInputs = [...hingeHolesPosInputs, existingMachiningConfig.hingeHolesPos[i].y];
                        hingeHolesPosInputsErrors = [...hingeHolesPosInputsErrors, false];
                        //  set the hinge holes pos limits from rowConfig
                        hingeHolesPosInputsLimits = existingMachiningConfig.hingeHolesPosLimits;
                    }
                }else if(existingMachiningConfig.hingeHolesSide == 'w2'){
                    // add each hole to the drawing props (scaled)
                    const scalePercentage = widthScale;
                    let hingeHolesPosInputs = [];
                    let hingeHolesPosInputsLimits = [];
                    let hingeHolesPosInputsErrors = [];
                    for(let i = 0; i < parseInt(existingMachiningConfig.hingeHoles); i++){
                        let scaledHingeHole = {
                            x: drawingLength - 50 * scalePercentage + drawingPadding,
                            y: parseInt(existingMachiningConfig.hingeHolesPos[i].y) * scalePercentage + drawingPadding,
                        } 
                        scaledHingeHolesPos = [...scaledHingeHolesPos, scaledHingeHole];
                        //  set the hinge holes pos inputs values based on their current position
                        hingeHolesPosInputs = [...hingeHolesPosInputs, existingMachiningConfig.hingeHolesPos[i].y];
                        hingeHolesPosInputsErrors = [...hingeHolesPosInputsErrors, false];
                        //  set the hinge holes pos limits from rowConfig
                        hingeHolesPosInputsLimits = existingMachiningConfig.hingeHolesPosLimits;
                    }
                }
            }

            //  check for adjustable shelf drilling (clusters)
            let scaledClustersPosArray = [];
            let scaledStep = null;
            if(existingMachiningConfig.clusters != null){
                // clustersHoles: [],
                // clusterStep: null,
                if(existingMachiningConfig.clustersEdge == 'l1-l2'){
                    //  calculate scaled positions for drawingProps
                    const scalePercentageX = lengthScale;
                    const scalePercentageY = widthScale;
                    const clusters_step = parseInt(existingMachiningConfig.clustersStep);
                    const clusters_distance1 = parseInt(existingMachiningConfig.clustersDistance1);
                    const clusters_distance2 = parseInt(existingMachiningConfig.clustersDistance2);
                    scaledStep = clusters_step * scalePercentageX;
                    scaledClustersPosArray = [...scaledClustersPosArray, {x: existingMachiningConfig.clustersPos[0] * scalePercentageX + drawingPadding, y1: drawingPadding + clusters_distance1 * scalePercentageY, y2: (parseInt(currRowConfig.width) - clusters_distance2) * scalePercentageY + drawingPadding}];
                    for(let i = 1; i < parseInt(existingMachiningConfig.clusters); i++){
                        const this_cluster_scaled_pos = {x: existingMachiningConfig.clustersPos[i] * scalePercentageX + drawingPadding, y1: drawingPadding + clusters_distance1 * scalePercentageY, y2: (parseInt(currRowConfig.width) - clusters_distance2) * scalePercentageY + drawingPadding};
                        scaledClustersPosArray = [...scaledClustersPosArray, this_cluster_scaled_pos];
                    }
                    //  set the shelf drilling input values
                    const savedInputValues = {
                        distance1: existingMachiningConfig.clustersDistance1,
                        distance2: existingMachiningConfig.clustersDistance2,
                        step: existingMachiningConfig.clustersStep,
                        positions: existingMachiningConfig.clustersPositions,
                        number_of_clusters: existingMachiningConfig.clusters,
                    }
                    //  set the clusters pos inputs limits error as false
                    let savedClustersPosInputsErrors = []
                    for(let i = 0; i < existingMachiningConfig.clusters; i++){
                        savedClustersPosInputsErrors = [...savedClustersPosInputsErrors, false];
                    }
                }else if(existingMachiningConfig.clustersEdge == 'w1-w2'){
                    const scalePercentageX = lengthScale;
                    const scalePercentageY = widthScale;
                    const clusters_step = parseInt(existingMachiningConfig.clustersStep);
                    const clusters_distance1 = parseInt(existingMachiningConfig.clustersDistance1);
                    const clusters_distance2 = parseInt(existingMachiningConfig.clustersDistance2);
                    scaledStep = clusters_step * scalePercentageY;
                    scaledClustersPosArray = [...scaledClustersPosArray, {x1: drawingPadding + clusters_distance1 * scalePercentageX, y: existingMachiningConfig.clustersPos[0] * scalePercentageY + drawingPadding, x2: (parseInt(currRowConfig.materialLength) - clusters_distance2) * scalePercentageX + drawingPadding}];
                    for(let i = 1; i < parseInt(existingMachiningConfig.clusters); i++){
                        const this_cluster_scaled_pos = {x1: drawingPadding + clusters_distance1 * scalePercentageX, y: existingMachiningConfig.clustersPos[i] * scalePercentageY + drawingPadding, x2: (parseInt(currRowConfig.materialLength) - clusters_distance2) * scalePercentageX + drawingPadding};
                        scaledClustersPosArray = [...scaledClustersPosArray, this_cluster_scaled_pos];
                    }
                    //  set the shelf drilling input values
                    const savedInputValues = {
                        distance1: existingMachiningConfig.clustersDistance1,
                        distance2: existingMachiningConfig.clustersDistance2,
                        step: existingMachiningConfig.clustersStep,
                        positions: existingMachiningConfig.clustersPositions,
                        number_of_clusters: existingMachiningConfig.clusters,
                    }
                    //  set the clusters pos inputs limits error as false
                    let savedClustersPosInputsErrors = []
                    for(let i = 0; i < existingMachiningConfig.clusters; i++){
                        savedClustersPosInputsErrors = [...savedClustersPosInputsErrors, false];
                    }
                }
            }

            setDrawingProps((prevValues) => ({
                ...prevValues,
                topLeft: topLeft,
                topRight: topRight,
                bottomRight: bottomRight,
                bottomLeft: bottomLeft,
                L1P: L1P,
                L2P: L2P,
                W1P: W1P,
                W2P: W2P,
                points: pointsString,
                drawingLength: drawingLength,
                drawingWidth: drawingWidth,
                l1w1_arrows: l1w1_arrows,
                l1w2_arrows: l1w2_arrows,
                l2w1_arrows: l2w1_arrows,
                l2w2_arrows: l2w2_arrows,
                l1w1_edgebanding: l1w1EdgebandingString,
                l1w2_edgebanding: l1w2EdgebandingString,
                l2w1_edgebanding: l2w1EdgebandingString,
                l2w2_edgebanding: l2w2EdgebandingString,
                hingeHoles: scaledHingeHolesPos,
                clustersHoles: scaledClustersPosArray,
                clusterStep: scaledStep,
            }));

            const new_drawing_props = {topLeft: topLeft,
                topRight: topRight,
                bottomRight: bottomRight,
                bottomLeft: bottomLeft,
                points: pointsString,
                drawingLength: drawingLength,
                drawingWidth: drawingWidth,
                l1w1_arrows: l1w1_arrows,
                l1w2_arrows: l1w2_arrows,
                l2w1_arrows: l2w1_arrows,
                l2w2_arrows: l2w2_arrows,
                l1w1_edgebanding: l1w1EdgebandingString,
                l1w2_edgebanding: l1w2EdgebandingString,
                l2w1_edgebanding: l2w1EdgebandingString,
                l2w2_edgebanding: l2w2EdgebandingString,
                hingeHoles: scaledHingeHolesPos,
                clustersHoles: scaledClustersPosArray,
                clusterStep: scaledStep,}
                console.log('-> new drawing props:');
                console.log(new_drawing_props);

        }else{  // if there is no existing machiningConfig in the rowConfig
            let topLeft = null;
            let topRight = null;
            let bottomRight = null;
            let bottomLeft = null;
            console.log(currRowConfig);
            console.log('rowConfig length: ' + inputLength);
            console.log('rowConfig width: ' + inputWidth);
            if(inputLength >= inputWidth){
                // calculate the percentage of width out of length
                let percentage = (inputWidth / inputLength);
                const newSvgWidth = viewDimensions.viewWidth;
                if(percentage < 0.25){
                    percentage = 0.25;
                }
                let newSvgHeight = viewDimensions.viewWidth * percentage;
                if(newSvgHeight > viewDimensions.viewHeight){
                    newSvgHeight = viewDimensions.viewHeight;
                }

                console.log('viewWidth: ' + viewDimensions.viewWidth);
                console.log('viewHeight: ' + viewDimensions.viewHeight);
                setSvgDimensions(() => ({
                    svgWidth: newSvgWidth,
                    svgHeight: newSvgHeight,
                }));
    
                // points positions for the full size panel
                let fTopLeft = { x: 0, y: 0 };
                let fTopRight = { x: inputLength , y: 0 };
                let fBottomRight = { x: inputLength, y: inputWidth};
                let fBottomLeft = { x: 0, y: inputWidth };

                // points positions for the drawing display (scaled)
                topLeft = { x: drawingPadding, y: drawingPadding };
                topRight = { x: newSvgWidth - drawingPadding, y: drawingPadding };
                bottomRight = { x: newSvgWidth - drawingPadding, y: newSvgHeight  - drawingPadding};
                bottomLeft = { x: drawingPadding, y: newSvgHeight - drawingPadding };
                
                const drawingLength = newSvgWidth - drawingPadding * 2;
                const drawingWidth = newSvgHeight - drawingPadding * 2;

                console.log('drawingLength: ' + drawingLength);
                console.log('drawingWidth: ' + drawingWidth);

                // write the points string for the "from X" arrows
                const l1w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                    from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                };
                const l1w2_arrows = {
                    from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                    from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                };
                const l2w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                };
                const l2w2_arrows = {
                    from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                };
                console.log('l1w1_arrows.from_w2: ' + l1w1_arrows.from_w2);

                const pointsArray = [topLeft, topRight, bottomRight, bottomLeft];
                console.log(pointsArray);
                // Convert the points array to a string
                const pointsString = `${topLeft.x},${topLeft.y} ${topRight.x},${topRight.y} ${bottomRight.x},${bottomRight.y} ${bottomLeft.x},${bottomLeft.y}`;
    
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    topLeft: fTopLeft,
                    topRight: fTopRight,
                    bottomRight: fBottomRight,
                    bottomLeft: fBottomLeft,
                }));
    
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    topLeft: topLeft,
                    topRight: topRight,
                    bottomRight: bottomRight,
                    bottomLeft: bottomLeft,
                    points: pointsString,
                    drawingLength: drawingLength,
                    drawingWidth: drawingWidth,
                    l1w1_arrows: l1w1_arrows,
                    l1w2_arrows: l1w2_arrows,
                    l2w1_arrows: l2w1_arrows,
                    l2w2_arrows: l2w2_arrows,
                }));
    
                console.log(drawingProps);
    
                console.log('Svg width:' + newSvgWidth);
                console.log('Svg height' + newSvgHeight);
            }else{
                // calculate the percentage of length out of width
                let percentage = (inputLength / inputWidth);
                const newSvgHeight = viewDimensions.viewHeight;
                if(percentage < 0.25){
                    percentage = 0.25;
                }
                let newSvgWidth = viewDimensions.viewHeight * percentage;
                if(newSvgWidth > viewDimensions.viewWidth){
                    newSvgWidth = viewDimensions.viewWidth;
                }

                console.log('viewWidth: ' + viewDimensions.viewWidth);
                console.log('viewHeight: ' + viewDimensions.viewHeight);
                setSvgDimensions(() => ({
                    svgWidth: newSvgWidth,
                    svgHeight: newSvgHeight,
                }));

                // points positions for the full size panel
                let fTopLeft = { x: 0, y: 0 };
                let fTopRight = { x: inputLength , y: 0 };
                let fBottomRight = { x: inputLength, y: inputWidth};
                let fBottomLeft = { x: 0, y: inputWidth };

                // points positions for the drawing display (scaled)
                topLeft = { x: drawingPadding, y: drawingPadding };
                topRight = { x: newSvgWidth - drawingPadding, y: drawingPadding };
                bottomRight = { x: newSvgWidth - drawingPadding, y: newSvgHeight  - drawingPadding};
                bottomLeft = { x: drawingPadding, y: newSvgHeight - drawingPadding };
                
                const drawingLength = newSvgWidth - drawingPadding * 2;
                const drawingWidth = newSvgHeight - drawingPadding * 2;

                console.log('drawingLength: ' + drawingLength);
                console.log('drawingWidth: ' + drawingWidth);

                // write the points string for the "from X" arrows
                const l1w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                    from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                };
                const l1w2_arrows = {
                    from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                    from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                };
                const l2w1_arrows = {
                    from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                };
                const l2w2_arrows = {
                    from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                    from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                };
                console.log('l1w1_arrows.from_w2: ' + l1w1_arrows.from_w2);

                const pointsArray = [topLeft, topRight, bottomRight, bottomLeft];
                console.log(pointsArray);
                // Convert the points array to a string
                const pointsString = `${topLeft.x},${topLeft.y} ${topRight.x},${topRight.y} ${bottomRight.x},${bottomRight.y} ${bottomLeft.x},${bottomLeft.y}`;
    
                setMachiningConfig((prevValues) => ({
                    ...prevValues,
                    topLeft: fTopLeft,
                    topRight: fTopRight,
                    bottomRight: fBottomRight,
                    bottomLeft: fBottomLeft,
                }));
    
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    topLeft: topLeft,
                    topRight: topRight,
                    bottomRight: bottomRight,
                    bottomLeft: bottomLeft,
                    points: pointsString,
                    drawingLength: drawingLength,
                    drawingWidth: drawingWidth,
                    l1w1_arrows: l1w1_arrows,
                    l1w2_arrows: l1w2_arrows,
                    l2w1_arrows: l2w1_arrows,
                    l2w2_arrows: l2w2_arrows,
                }));
    
                console.log(drawingProps);
    
                console.log('Svg width:' + newSvgWidth);
                console.log('Svg height' + newSvgHeight);
            }
        }
    };

    // function to display the drawing
    const displayPanelDrawing = () => {
        console.log('-> /// DISPLAY DRAWING');
        if(rowConfig != null && machiningConfig != null){
            console.log('displayPanelDrawing() rowConfig check passed');
            if((rowConfig.machiningConfig != null || drawingProps.points != null) && machiningConfig.topLeft != null && machiningConfig.topRight != null && machiningConfig.bottomLeft != null && machiningConfig.bottomRight != null){
                console.log('displayPanelDrawing() first if passed /////////////////////////////////////////////');
                console.log(machiningConfig);
                console.log(drawingProps);

                // get data from rowConfig.machiningConfig
                let pointsString = '';
                let l1w1EdgebandingString = null;
                let l1w2EdgebandingString = null;
                let l2w1EdgebandingString = null;
                let l2w2EdgebandingString = null;

                // check for l1w1 cut
                if(machiningConfig.W1P != null && drawingProps.W1P != null){
                    let W1P = drawingProps.W1P;
                    let W1P_points = `${W1P.x},${W1P.y}`;
                    let TL = drawingProps.topLeft;
                    let TL_points = `${TL.x},${TL.y}`;
                    pointsString = `${W1P_points} ${TL_points}`;
                    console.log(`-> W1P: ${W1P.x}; ${W1P.y}`);
                    console.log(`-> TL: ${TL.x}; ${TL.y}`);

                    if(machiningConfig.l1w1_edgebanding != null){
                        console.log(`-> W1P for edgebanding: ${W1P.x}; ${W1P.y}`);
                        console.log(`-> TL for edgebanding: ${TL.x}; ${TL.y}`);
                        l1w1EdgebandingString = `M${W1P.x - 2.5} ${W1P.y - 2.5} L${TL.x - 2.5} ${TL.y - 2.5}`;
                    }
                }else{
                    let TL = drawingProps.topLeft;
                    let TL_points = `${TL.x},${TL.y}`;
                    pointsString = `${TL_points}`;
                }
                // check for l1w2 cut
                if(machiningConfig.L1P != null && drawingProps.L1P != null){
                    let L1P = drawingProps.L1P;
                    let L1P_points = `${L1P.x},${L1P.y}`;
                    let TR = drawingProps.topRight;
                    let TR_points = `${TR.x},${TR.y}`;
                    pointsString = `${pointsString} ${L1P_points} ${TR_points}`;

                    if(machiningConfig.l1w2_edgebanding != null){
                        l1w2EdgebandingString = `M${L1P.x + 2.5} ${L1P.y - 2.5} L${TR.x + 2.5} ${TR.y - 2.5}`;
                    }
                }else{
                    let TR = drawingProps.topRight;
                    let TR_points = `${TR.x},${TR.y}`;
                    pointsString = `${pointsString} ${pointsString} ${TR_points}`;
                }
                // check for l2w2 cut
                if(machiningConfig.W2P != null && drawingProps.W2P != null){
                    let W2P = drawingProps.W2P;
                    let W2P_points = `${W2P.x},${W2P.y}`;
                    let BR = drawingProps.bottomRight;
                    let BR_points = `${BR.x},${BR.y}`;
                    pointsString = `${pointsString} ${W2P_points} ${BR_points}`;

                    if(machiningConfig.l2w2_edgebanding != null){
                        l2w2EdgebandingString = `M${W2P.x + 2.5} ${W2P.y + 2.5} L${BR.x + 2.5} ${BR.y + 2.5}`;
                    }
                }else{
                    let BR = drawingProps.bottomRight;
                    let BR_points = `${BR.x},${BR.y}`;
                    pointsString = `${pointsString} ${BR_points}`;
                }
                // check for l2w1 cut
                if(machiningConfig.L2P != null && drawingProps.L2P != null){
                    let L2P = drawingProps.L2P;
                    let L2P_points = `${L2P.x},${L2P.y}`;
                    let BL= drawingProps.bottomLeft;
                    let BL_points = `${BL.x},${BL.y}`;
                    pointsString = `${pointsString} ${L2P_points} ${BL_points}`;

                    if(machiningConfig.l2w1_edgebanding != null){
                        l2w1EdgebandingString = `M${L2P.x - 2.5} ${L2P.y + 2.5} L${BL.x - 2.5} ${BL.y + 2.5}`;
                    }
                }else{
                    let BL= drawingProps.bottomLeft;
                    let BL_points = `${BL.x},${BL.y}`;
                    pointsString = `${pointsString} ${BL_points}`;
                }
    
                console.log('New pointsString with cuts: ' + pointsString);
                setDrawingProps((prevValues) => ({
                    ...prevValues,
                    points: pointsString,
                    l1w1_edgebanding: l1w1EdgebandingString,
                    l1w2_edgebanding: l1w2EdgebandingString,
                    l2w1_edgebanding: l2w1EdgebandingString,
                    l2w2_edgebanding: l2w2EdgebandingString,
                }));
            }else if(machiningConfig.topLeft == null || machiningConfig.topRight == null || machiningConfig.bottomLeft == null || machiningConfig.bottomRight == null){
                console.log('displayPanelDrawing() second if passed //////////////////////////////////////////////');
                // create a drawing display from the inputs
                let topLeft = null;
                let topRight = null;
                let bottomRight = null;
                let bottomLeft = null;
                let inputLength = parseInt(rowConfig.materialLength, 10);
                let inputWidth = parseInt(rowConfig.width, 10);
                console.log(rowConfig);
                console.log('rowConfig length: ' + inputLength);
                console.log('rowConfig width: ' + inputWidth);
                if(inputLength >= inputWidth){
                    // calculate the percentage of width out of length
                    let percentage = (inputWidth / inputLength);
                    const newSvgWidth = viewDimensions.viewWidth;
                    if(percentage < 0.25){
                        percentage = 0.25;
                    }
                    let newSvgHeight = viewDimensions.viewWidth * percentage;
                    if(newSvgHeight > viewDimensions.viewHeight){
                        newSvgHeight = viewDimensions.viewHeight;
                    }
    
                    console.log('viewWidth: ' + viewDimensions.viewWidth);
                    console.log('viewHeight: ' + viewDimensions.viewHeight);
                    setSvgDimensions(() => ({
                        svgWidth: newSvgWidth,
                        svgHeight: newSvgHeight,
                    }));
        
                    // points positions for the full size panel
                    let fTopLeft = { x: 0, y: 0 };
                    let fTopRight = { x: inputLength , y: 0 };
                    let fBottomRight = { x: inputLength, y: inputWidth};
                    let fBottomLeft = { x: 0, y: inputWidth };
    
                    // points positions for the drawing display (scaled)
                    topLeft = { x: drawingPadding, y: drawingPadding };
                    topRight = { x: newSvgWidth - drawingPadding, y: drawingPadding };
                    bottomRight = { x: newSvgWidth - drawingPadding, y: newSvgHeight  - drawingPadding};
                    bottomLeft = { x: drawingPadding, y: newSvgHeight - drawingPadding };
                    
                    const drawingLength = newSvgWidth - drawingPadding * 2;
                    const drawingWidth = newSvgHeight - drawingPadding * 2;

                    console.log('drawingLength: ' + drawingLength);
                    console.log('drawingWidth: ' + drawingWidth);

                    // write the points string for the "from X" arrows
                    const l1w1_arrows = {
                        from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                        from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                    };
                    const l1w2_arrows = {
                        from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                        from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                    };
                    const l2w1_arrows = {
                        from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                        from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                    };
                    const l2w2_arrows = {
                        from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                        from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                    };
                    console.log('l1w1_arrows.from_w2: ' + l1w1_arrows.from_w2);

                    const pointsArray = [topLeft, topRight, bottomRight, bottomLeft];
                    console.log(pointsArray);
                    // Convert the points array to a string
                    const pointsString = `${topLeft.x},${topLeft.y} ${topRight.x},${topRight.y} ${bottomRight.x},${bottomRight.y} ${bottomLeft.x},${bottomLeft.y}`;
        
                    setMachiningConfig((prevValues) => ({
                        ...prevValues,
                        topLeft: fTopLeft,
                        topRight: fTopRight,
                        bottomRight: fBottomRight,
                        bottomLeft: fBottomLeft,
                    }));
        
                    setDrawingProps((prevValues) => ({
                        ...prevValues,
                        topLeft: topLeft,
                        topRight: topRight,
                        bottomRight: bottomRight,
                        bottomLeft: bottomLeft,
                        points: pointsString,
                        drawingLength: drawingLength,
                        drawingWidth: drawingWidth,
                        l1w1_arrows: l1w1_arrows,
                        l1w2_arrows: l1w2_arrows,
                        l2w1_arrows: l2w1_arrows,
                        l2w2_arrows: l2w2_arrows,
                    }));
        
                    console.log(drawingProps);
        
                    console.log('Svg width:' + newSvgWidth);
                    console.log('Svg height' + newSvgHeight);
                }else{
                    // calculate the percentage of length out of width
                    let percentage = (inputLength / inputWidth);
                    const newSvgHeight = viewDimensions.viewHeight;
                    if(percentage < 0.25){
                        percentage = 0.25;
                    }
                    let newSvgWidth = viewDimensions.viewHeight * percentage;
                    if(newSvgWidth > viewDimensions.viewWidth){
                        newSvgWidth = viewDimensions.viewWidth;
                    }
    
                    console.log('viewWidth: ' + viewDimensions.viewWidth);
                    console.log('viewHeight: ' + viewDimensions.viewHeight);
                    setSvgDimensions(() => ({
                        svgWidth: newSvgWidth,
                        svgHeight: newSvgHeight,
                    }));

                    // points positions for the full size panel
                    let fTopLeft = { x: 0, y: 0 };
                    let fTopRight = { x: inputLength , y: 0 };
                    let fBottomRight = { x: inputLength, y: inputWidth};
                    let fBottomLeft = { x: 0, y: inputWidth };
    
                    // points positions for the drawing display (scaled)
                    topLeft = { x: drawingPadding, y: drawingPadding };
                    topRight = { x: newSvgWidth - drawingPadding, y: drawingPadding };
                    bottomRight = { x: newSvgWidth - drawingPadding, y: newSvgHeight  - drawingPadding};
                    bottomLeft = { x: drawingPadding, y: newSvgHeight - drawingPadding };
                    
                    const drawingLength = newSvgWidth - drawingPadding * 2;
                    const drawingWidth = newSvgHeight - drawingPadding * 2;

                    console.log('drawingLength: ' + drawingLength);
                    console.log('drawingWidth: ' + drawingWidth);

                    // write the points string for the "from X" arrows
                    const l1w1_arrows = {
                        from_w2: `M${drawingLength + drawingPadding} 10 L${drawingPadding} 10 M${drawingPadding + 5} 5 L${drawingPadding} 10 M${drawingPadding + 5} 15 L${drawingPadding} 10`,
                        from_l2: `M10 ${drawingWidth + drawingPadding} L10 ${drawingPadding} M5 ${drawingPadding + 5} L10 ${drawingPadding} M15 ${drawingPadding + 5} L10 ${drawingPadding}`
                    };
                    const l1w2_arrows = {
                        from_w1: `M${drawingPadding} 10 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 5 L${drawingLength + drawingPadding} 10 M${drawingLength + drawingPadding - 5} 15 L${drawingLength + drawingPadding} 10`,
                        from_l2: `M${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingPadding + 5} L${drawingLength + drawingPadding + 10} ${drawingPadding}`
                    };
                    const l2w1_arrows = {
                        from_w2: `M${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 5} L${drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingPadding + 5} ${drawingWidth + drawingPadding + 15} L${drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                        from_l1: `M10 ${drawingPadding} L10 ${drawingWidth + drawingPadding} M5 ${drawingWidth + drawingPadding - 5} L10 ${drawingWidth + drawingPadding} M15 ${drawingWidth + drawingPadding -5} L10 ${drawingWidth + drawingPadding}`
                    };
                    const l2w2_arrows = {
                        from_w1: `M${drawingPadding} ${drawingWidth + drawingPadding + 10} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 5} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10} M${drawingLength + drawingPadding - 5} ${drawingWidth + drawingPadding + 15} L${drawingLength + drawingPadding} ${drawingWidth + drawingPadding + 10}`,
                        from_l1: `M${drawingLength  + drawingPadding+ 10} ${drawingPadding} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 5} ${drawingWidth + drawingPadding - 5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding} M${drawingLength + drawingPadding + 15} ${drawingWidth + drawingPadding -5} L${drawingLength + drawingPadding + 10} ${drawingWidth + drawingPadding}`
                    };
                    console.log('l1w1_arrows.from_w2: ' + l1w1_arrows.from_w2);

                    const pointsArray = [topLeft, topRight, bottomRight, bottomLeft];
                    console.log(pointsArray);
                    // Convert the points array to a string
                    const pointsString = `${topLeft.x},${topLeft.y} ${topRight.x},${topRight.y} ${bottomRight.x},${bottomRight.y} ${bottomLeft.x},${bottomLeft.y}`;
        
                    setMachiningConfig((prevValues) => ({
                        ...prevValues,
                        topLeft: fTopLeft,
                        topRight: fTopRight,
                        bottomRight: fBottomRight,
                        bottomLeft: fBottomLeft,
                    }));
        
                    setDrawingProps((prevValues) => ({
                        ...prevValues,
                        topLeft: topLeft,
                        topRight: topRight,
                        bottomRight: bottomRight,
                        bottomLeft: bottomLeft,
                        points: pointsString,
                        drawingLength: drawingLength,
                        drawingWidth: drawingWidth,
                        l1w1_arrows: l1w1_arrows,
                        l1w2_arrows: l1w2_arrows,
                        l2w1_arrows: l2w1_arrows,
                        l2w2_arrows: l2w2_arrows,
                    }));
        
                    console.log(drawingProps);
        
                    console.log('Svg width:' + newSvgWidth);
                    console.log('Svg height' + newSvgHeight);
                }
            
            }   
        }
    };

    return (
        <div className='summaryPopup' onClick={handleContainerClick}>
            <div className='summaryPopupContainer'>
                <div className='summaryPopupContent'>
                    <div className='summaryPopupCloseBtn' onClick={() => closePopup()}>
                        <FiX />
                        <p>Close & save</p>
                    </div>
                    <div className='summaryControlContainer'>
                        <p>Panel summary</p>
                        <div className='summaryCategory'>
                            <div className='summaryCategoryTop'>
                                <p>General</p>
                            </div>
                            <div className='summaryCategoryContent'>
                                <p>Brand: {selectedMaterial?.manufacturer}</p>
                                <p>Product code: {rowConfig?.productCode}</p>
                                <p>Looks: {selectedMaterial?.color_description}</p>
                                <p>Dimensions: {rowConfig?.materialLength} x {rowConfig?.width} x {rowConfig?.thickness} mm</p>
                                <p>Quantity: {rowConfig?.quantity}</p>
                            </div>
                        </div>

                        {rowConfig?.edgebandingConfig != null && (rowConfig?.edgebandingConfig.l1 == true || rowConfig?.edgebandingConfig.l2 == true || rowConfig?.edgebandingConfig.w1 == true || rowConfig?.edgebandingConfig.w2 == true )  ? (
                            <div className='summaryCategory'>
                                <div className='summaryCategoryTop'>
                                    <p>Edgebanding</p>
                                </div>
                                <div className='summaryCategoryContent'>
                                    {rowConfig?.edgebandingConfig.l1 == true ? (
                                        <p>L1: {rowConfig?.edgebandingConfig.l1_finish.charAt(0).toUpperCase() + rowConfig?.edgebandingConfig.l1_finish.slice(1)} finish</p>
                                    ) : null}
                                    {rowConfig?.edgebandingConfig.l2 == true ? (
                                        <p>L2: {rowConfig?.edgebandingConfig.l2_finish.charAt(0).toUpperCase() + rowConfig?.edgebandingConfig.l2_finish.slice(1)} finish</p>
                                    ) : null}
                                    {rowConfig?.edgebandingConfig.w1 == true ? (
                                        <p>W1: {rowConfig?.edgebandingConfig.w1_finish.charAt(0).toUpperCase() + rowConfig?.edgebandingConfig.w1_finish.slice(1)} finish</p>
                                    ) : null}
                                    {rowConfig?.edgebandingConfig.w2 == true ? (
                                        <p>W2: {rowConfig?.edgebandingConfig.w2_finish.charAt(0).toUpperCase() + rowConfig?.edgebandingConfig.w2_finish.slice(1)} finish</p>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}

                        {rowConfig?.machiningConfig != null && (rowConfig?.machiningConfig.L1P != null || rowConfig?.machiningConfig.L2P != null || rowConfig.machiningConfig.W1P != null || rowConfig.machiningConfig.W2P != null) ? (
                            <div className='summaryCategory'>
                                <div className='summaryCategoryTop'>
                                    <p>Angle cuts</p>
                                </div>
                                <div className='summaryCategoryContent'>
                                    {rowConfig.machiningConfig.W1P != null ? (
                                        <p>Cut on L1-W1 with {rowConfig?.machiningConfig.l1w1_edgebanding != null ? `${rowConfig?.machiningConfig.l1w1_edgebanding.finish.charAt(0).toUpperCase() + rowConfig?.machiningConfig.l1w1_edgebanding.finish.slice(1)} finish edgebanding` : 'No edgebanding'}</p>
                                    ) : null}
                                    {rowConfig.machiningConfig.L1P != null ? (
                                        <p>Cut on L1-W2 with {rowConfig?.machiningConfig.l1w2_edgebanding != null ? `${rowConfig?.machiningConfig.l1w2_edgebanding.finish.charAt(0).toUpperCase() + rowConfig?.machiningConfig.l1w2_edgebanding.finish.slice(1)} finish edgebanding` : 'No edgebanding'}</p>
                                    ) : null}
                                    {rowConfig.machiningConfig.W2P != null ? (
                                        <p>Cut on L2-W2 with {rowConfig?.machiningConfig.l2w2_edgebanding != null ? `${rowConfig?.machiningConfig.l2w2_edgebanding.finish.charAt(0).toUpperCase() + rowConfig?.machiningConfig.l2w2_edgebanding.finish.slice(1)} finish edgebanding` : 'No edgebanding'}</p>
                                    ) : null}
                                    {rowConfig.machiningConfig.L2P != null ? (
                                        <p>Cut on L2-W1 with {rowConfig?.machiningConfig.l2w1_edgebanding != null ? `${rowConfig?.machiningConfig.l2w1_edgebanding.finish.charAt(0).toUpperCase() + rowConfig?.machiningConfig.l2w1_edgebanding.finish.slice(1)} finish edgebanding` : 'No edgebanding'}</p>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}

                        {rowConfig?.machiningConfig != null && rowConfig?.machiningConfig.hingeHoles != null ? (
                            <div className='summaryCategory'>
                                <div className='summaryCategoryTop'>
                                    <p>Hinge drilling</p>
                                </div>
                                <div className='summaryCategoryContent'>
                                    <p>Type: {rowConfig?.machiningConfig.hingesType == 'screwOn' ? 'Blum 35mm Screw-On' : 'Blum 35mm INSERTA'}</p>
                                    <p>Number of holes: {rowConfig?.machiningConfig.hingeHoles}</p>
                                </div>
                            </div>
                        ) : null}

                        {rowConfig?.machiningConfig != null && rowConfig?.machiningConfig.clusters != null ? (
                            <div className='summaryCategory'>
                                <div className='summaryCategoryTop'>
                                    <p>Adjustable shelf drilling</p>
                                </div>
                                <div className='summaryCategoryContent'>
                                    <p>Type: {rowConfig?.machiningConfig.clustersType} Ø (8mm deep)</p>
                                    <p>Number of clusters: {rowConfig?.machiningConfig.clusters}</p>
                                    <p>Step: {rowConfig?.machiningConfig.clustersStep}</p>
                                    <p>Positions: {rowConfig?.machiningConfig.clustersPositions}</p>
                                </div>
                            </div>
                        ) : null}

                        {rowConfig?.sprayConfig != null ? (
                            <div className='summaryCategory'>
                                <div className='summaryCategoryTop'>
                                    <p>Spray coating</p>
                                </div>
                                <div className='summaryCategoryContent'>
                                    <p>Type: {(() => {
                                            switch (rowConfig?.sprayConfig.spray) {
                                            case 'whitePrimer':
                                                return 'White primer';
                                            case 'solidColor':
                                                return 'Solid colour paint';
                                            case 'clearLac':
                                                return 'Clear lacquer';
                                            default:
                                                return '';
                                            }
                                        })()}
                                    </p>
                                    <p>Backside: 
                                        {(() => {
                                            if (rowConfig?.sprayConfig.spray === 'whitePrimer') {
                                            if (rowConfig?.sprayConfig.backside !== null) {
                                                return 'White primer';
                                            }
                                            } else if (rowConfig?.sprayConfig.spray === 'solidColor') {
                                            if (rowConfig?.sprayConfig.backside === 'color') {
                                                return 'Solid colour paint';
                                            } else if (rowConfig?.sprayConfig.backside === 'white primer') {
                                                return 'White primer';
                                            }
                                            } else if (rowConfig?.sprayConfig.spray === 'clearLacquer') {
                                            if (rowConfig?.sprayConfig.backside === 'lac') {
                                                return 'Clear lacquer';
                                            } else if (rowConfig?.sprayConfig.backside === 'clear sealant') {
                                                return 'Clear sealant';
                                            }
                                            }
                                            return 'No spray coating'; // Default case, adjust as needed
                                        })()}
                                    </p>
                                    {rowConfig?.sprayConfig.spray == 'solidColor' ? (
                                        <>
                                            <p>Paint brand: {rowConfig?.sprayConfig.paintBrand}</p>
                                            <p>Color: {rowConfig?.sprayConfig.colorName}</p>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}



                    </div>
                    <div className='machiningPopupDrawingContainer'>
                    <div className='machiningPopupDrawing' ref={machiningPopupDrawingRef}>
                        <svg height={svgDimensions.svgHeight} width={svgDimensions.svgWidth}>
                            {/* panel drawing */}
                            <polygon points={drawingProps.points} fill="#e4d5c7" stroke={'#6ab29b'} strokeWidth={1}/>
                            {/* edgebanding lines */}
                            {machiningConfig?.l1w1_edgebanding !== null && drawingProps.l1w1_edgebanding !== null && (
                                <>
                                    <path
                                        d={drawingProps.l1w1_edgebanding}
                                        stroke="#6ab29b"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                    />
                                </>
                            )}
                            {machiningConfig?.l1w2_edgebanding !== null && drawingProps.l1w2_edgebanding !== null && (
                                <>
                                    <path
                                        d={drawingProps.l1w2_edgebanding}
                                        stroke="#6ab29b"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                    />
                                </>
                            )}
                            {machiningConfig?.l2w2_edgebanding !== null && drawingProps.l2w2_edgebanding !== null && (
                                <>
                                    <path
                                        d={drawingProps.l2w2_edgebanding}
                                        stroke="#6ab29b"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                    />
                                </>
                            )}
                            {machiningConfig?.l2w1_edgebanding !== null && drawingProps.l2w1_edgebanding !== null && (
                                <>
                                    <path
                                        d={drawingProps.l2w1_edgebanding}
                                        stroke="#6ab29b"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                    />
                                </>
                            )}


                            {/* hinge drilling */}
                            {machiningConfig?.hingeHoles != null && machiningConfig?.hingeHolesPos.length > 0 && drawingProps.hingeHoles.length > 0 ?
                                machiningConfig?.hingeHolesPos.map((hole, index) => (
                                    <React.Fragment key={`holeDraw${index}`}>
                                        <circle cx={drawingProps.hingeHoles[index].x} cy={drawingProps.hingeHoles[index].y} r="3" fill="#253237" />
                                    </React.Fragment>
                                ))
                            : null}

                            {/* shelf drilling */}
                            {machiningConfig?.clusters != null && machiningConfig?.clustersPos.length > 0 && drawingProps?.clustersHoles.length > 0 ?
                                drawingProps.clustersHoles.map((hole, index) => (
                                    <React.Fragment key={`holeDraw${index}`}>
                                        {(() => {
                                            const circles = [];
                                            if(machiningConfig?.clustersEdge == 'l1-l2'){
                                                for (let i = 0; i < machiningConfig?.clustersPositions; i++) {
                                                    console.log(`Drawing hole ${i + 1} for cluster ${index + 1}: x = ${drawingProps.clustersHoles[index].x + i * drawingProps.clusterStep}; y = ${drawingProps.clustersHoles[index].y1}`);
                                                    circles.push(
                                                        <circle
                                                            key={`circle${i}`}
                                                            cx={drawingProps.clustersHoles[index].x + i * drawingProps.clusterStep}
                                                            cy={drawingProps.clustersHoles[index].y1}
                                                            r="1"
                                                            fill="#253237"
                                                        />
                                                    );
                                                    circles.push(
                                                        <circle
                                                            key={`circleMirror${i}`}
                                                            cx={drawingProps.clustersHoles[index].x + i * drawingProps.clusterStep}
                                                            cy={drawingProps.clustersHoles[index].y2}
                                                            r="1"
                                                            fill="#253237"
                                                        />
                                                    );
                                                }
                                            }else if(machiningConfig?.clustersEdge == 'w1-w2'){
                                                for (let i = 0; i < machiningConfig?.clustersPositions; i++) {
                                                    console.log(`Drawing hole ${i + 1} for cluster ${index + 1}: x = ${drawingProps.clustersHoles[index].x1}; y = ${drawingProps.clustersHoles[index].y + i * drawingProps.clusterStep}`);
                                                    circles.push(
                                                        <circle
                                                            key={`circle${i}`}
                                                            cx={drawingProps.clustersHoles[index].x1}
                                                            cy={drawingProps.clustersHoles[index].y + i * drawingProps.clusterStep}
                                                            r="1"
                                                            fill="#253237"
                                                        />
                                                    );
                                                    circles.push(
                                                        <circle
                                                            key={`circleMirror${i}`}
                                                            cx={drawingProps.clustersHoles[index].x2}
                                                            cy={drawingProps.clustersHoles[index].y + i * drawingProps.clusterStep}
                                                            r="1"
                                                            fill="#253237"
                                                        />
                                                    );
                                                }
                                            }

                                            return circles;
                                        })()}
                                    </React.Fragment>
                                ))
                            : null}

                        </svg>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PanelSummary