import React, { useState, useEffect } from 'react'
import './fullSheetMaterialElement.css'
import { FiCheck } from "react-icons/fi";

import { pushToSelectedFullSheetsMaterials, selectedFullSheetsMaterialForRow } from '../../new_order/orderConfig';
import { Grid } from 'react-loader-spinner';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';

function FullSheetMaterialElement(props) {
    const [material, setMaterial] = useState(props.material);
    const [imgSrc, setImgSrc] = useState(process.env.PUBLIC_URL + '/res/images/materials/' + props.material.product_code + '-1.jpg');
    const [imageLoading, setImageLoading] = useState(false);
    const handleImageError = () => {
        setImgSrc(process.env.PUBLIC_URL + '/res/images/materials/placeholder.png');
        setImageLoading(false);
    };

    // Reset state when the material prop changes
    useEffect(() => {
        setImageLoading(false);
        setMaterial(props.material);
        setDetailsExpand(false);
        console.log('Material element props changed');
        setImgSrc(process.env.PUBLIC_URL + '/res/images/materials/' + props.material.product_code + '-1.jpg');
    }, [props.material]);

    const handleImageLoad = () => {
        setImageLoading(false); // Image loaded
    };


    //  toggle material details
    const [detailsExpand, setDetailsExpand] = useState(false);
    const toggleDetailsExpand = async() =>{
        setDetailsExpand(!detailsExpand);
        if(!detailsExpand == true){
            await thickBtnClicked(selectedThickness);
        }
    }

    const [thickArray, setThickArray] = useState([]);
    const [currOptions, setCurrOptions] = useState({
        cutting: null,
        edgebanding: null,
        cnc: null,
        drilling: null,
        sprayCoating: null,
        sheetPrice: null,
        cutPrice: null
    });
    const [selectedThickness, setSelectedThickness] = useState(0);
    const getAvailableThicknesses = async() => {
        const newArray = [];
        for (let i = 1; i <= 11; i++) {
            const thisThickness = {
                thickValue: null,
                cutting: null,
                edgebanding: null,
                drilling: null,
                cnc: null,
                sprayCoating: null,
                sheetPrice: null,
                cutPrice: null,
                bonded: false,
            };
        
            const thicknessKey = `thickness${i}`;
            const edgebandingKey = `${thicknessKey}_edgebanding`;
            const drillingKey = `${thicknessKey}_drilling`;
            const cncKey = `${thicknessKey}_cnc`;
            const sprayCoatingKey = `${thicknessKey}_spray_coating`;
            const sheetPriceKey = `${thicknessKey}_sheet_price`;
            const cutPriceKey = `${thicknessKey}_cut_price`;
            const bondedKey = `${thicknessKey}_glued`;
            
            if (material[thicknessKey] != null && material[thicknessKey] != '' && material[thicknessKey] != 0) {
                thisThickness.thickValue = material[thicknessKey];
                thisThickness.edgebanding = material[edgebandingKey];
                thisThickness.cutting = material.cutting;
                thisThickness.drilling = material[drillingKey];
                thisThickness.cnc = material[cncKey];
                thisThickness.sprayCoating = material[sprayCoatingKey];
                thisThickness.sheetPrice = material[sheetPriceKey];
                thisThickness.cutPrice = material[cutPriceKey];
                thisThickness.bonded = material[bondedKey].toLowerCase() == 'yes' ? true : false;
                newArray.push(thisThickness);
            }
        }
        console.log(newArray);
        setThickArray(newArray);
        return newArray;
    };

    const formatPrice = async(price) => {
        return price.toFixed(2); // Formats the number to two decimal places
    };

    const thickBtnClicked = async(thickId) => {
        setSelectedThickness(thickId);

        const newThickArray = await getAvailableThicknesses();

        console.log(newThickArray[thickId]);

        let sheetPriceForBonded = 0;
        let cutPriceForBonded = 0;
        if(newThickArray[thickId].sheetPrice == 0 && newThickArray[thickId].cutPrice == 0 && newThickArray[thickId].bonded == true){
            for(let i = 0; i < newThickArray.length; i++){
                if((newThickArray[i].thickValue * 2) == newThickArray[thickId].thickValue){
                    sheetPriceForBonded = newThickArray[i].sheetPrice * 2;
                    cutPriceForBonded = newThickArray[i].cutPrice * 2;
                    break;
                }
            }
        }


        setCurrOptions({
            cutting: newThickArray[thickId].cutting.toLowerCase(),
            edgebanding: newThickArray[thickId].edgebanding.toLowerCase(),
            cnc: newThickArray[thickId].cnc.toLowerCase(),
            drilling: newThickArray[thickId].drilling.toLowerCase(),
            sprayCoating: newThickArray[thickId].sprayCoating.toLowerCase(),
            sheetPrice: newThickArray[thickId].bonded == true ? await formatPrice(sheetPriceForBonded) : await formatPrice(newThickArray[thickId].sheetPrice),
            cutPrice: newThickArray[thickId].bonded == true ? await formatPrice(cutPriceForBonded) : await formatPrice(newThickArray[thickId].cutPrice)
        });
    }

    //  material has been selected
    const handleMaterialSelected = () =>{
        pushToSelectedFullSheetsMaterials(material);
        selectedFullSheetsMaterialForRow(material.product_code);
        props.fetchRows();
        props.closePopup();
    }

    const [isTablet, setIsTablet] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Function to update the state based on window width
        const handleResize = () => {
            setIsTablet(window.innerWidth > 768 && window.innerWidth < 1220);
            setIsMobile(window.innerWidth < 768);
        };

        // Initial check
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className='materialSelectListMaterial'>
            <div className='materialSelectListMaterialTop'>
                <Grid
                    visible={imageLoading}
                    height="50"
                    width="50"
                    color="#FF5A2E"
                    ariaLabel="grid-loading"
                    radius="6"
                    wrapperStyle={{padding: '20px'}}
                    wrapperClass="grid-wrapper"
                />
                <img src={imgSrc} alt='Material preview' style={{width: '100px', borderRadius: detailsExpand === false ? '16px 0 0 16px' : '16px 0 16px 0', display: imageLoading == true ? 'none' : 'block'}} onLoad={handleImageLoad} onError={handleImageError}/>
                <div className='materialSelectListMaterialTopText'>
                {isMobile === true ? (
                        <>
                            <div className='materialSelectListTopTextRow-mobile'>
                                <div className='materialSelectListGridItem'>
                                    <p>{material.product_code}</p>
                                </div>
                                <div className='materialSelectListGridItem'>
                                    <p>{material.color_description}</p>
                                </div>
                                <div className='materialSelectListGridItem'>
                                    <p>{material.material_type}</p>
                                </div>
                                <div className='materialSelectListGridItem'>
                                    <p>{material.manufacturer}</p>
                                </div>
                            </div>
                            <div>
                                <div className='materialSelectListGridItemInline-mobile'>
                                    <div className='materialSelectDetailsBtn' onClick={() => toggleDetailsExpand()}>
                                            {detailsExpand === false ? <IoEyeOutline /> : <IoEyeOffOutline />}
                                            <p>Details</p>
                                        </div>
                                    <div className='materialSelectSelectBtn' onClick={() => handleMaterialSelected()}>
                                        <p>Select</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='materialSelectListGridItem'>
                                <p>{material.product_code}</p>
                            </div>
                            <div className='materialSelectListGridItem'>
                                <p>{material.color_description}</p>
                            </div>
                            <div className='materialSelectListGridItem'>
                                <p>{material.material_type}</p>
                            </div>
                            <div className='materialSelectListGridItem'>
                                <p>{material.manufacturer}</p>
                            </div>
                            <div className='materialSelectListGridItem'>
                                {isTablet=== false && isMobile === false ? (
                                    <div className='materialSelectDetailsBtn' onClick={() => toggleDetailsExpand()}>
                                        <p>{detailsExpand === false ? 'More details' : 'Less details'}</p>
                                    </div>
                                ) : (
                                    <div className='materialSelectDetailsBtn' onClick={() => toggleDetailsExpand()}>
                                        {detailsExpand === false ? <IoEyeOutline /> : <IoEyeOffOutline />}
                                        <p>Details</p>
                                    </div>
                                )}
                            </div>
                            <div className='materialSelectListGridItem'>
                                <div className='materialSelectSelectBtn' onClick={() => handleMaterialSelected()}>
                                    <p>Select</p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {detailsExpand === true ? (
                <div className='materialSelectListMaterialBottom'>
                    <div className='materialSelectDetailsDouble'>
                        <div className='materialSelectDetailsDiv'>
                            <p className='materialSelectDetailsTitle'>Product characteristics:</p>
                            <p>{material.product_characteristics}</p>
                            <p className='materialSelectDetailsTitle'>Back side:</p>
                            <p>{material.back_side_description}</p>
                            <p className='materialSelectDetailsTitle'>Dimensions:</p>
                            <p>Length: {material.material_length} mm</p>
                            <p>Width: {material.width} mm</p>
                        </div>
                        <div className='materialSelectDetailsDiv'>
                            <p className='materialSelectDetailsTitle'>Options:</p>
                            {currOptions.cutting == 'yes' ? (
                                <div className='materialSelectDetailsOption'>
                                    <FiCheck />
                                    <p>Cutting</p>
                                </div>
                            ) : null}
                            {currOptions.edgebanding == 'yes' ? (
                                <div className='materialSelectDetailsOption'>
                                    <FiCheck />
                                    <p>Edgebanding</p>
                                </div>
                            ) : null}
                            {currOptions.cnc == 'yes' ? (
                                <div className='materialSelectDetailsOption'>
                                    <FiCheck />
                                    <p>CNC</p>
                                </div>
                            ) : null}
                            {currOptions.drilling == 'yes' ? (
                                <div className='materialSelectDetailsOption'>
                                    <FiCheck />
                                    <p>Drilling</p>
                                </div>
                            ) : null}
                            {currOptions.sprayCoating == 'yes' ? (
                                <div className='materialSelectDetailsOption'>
                                    <FiCheck />
                                    <p>Spray coating</p>
                                </div>
                            ) : null}
                            {(currOptions.cutting != 'yes' && currOptions.edgebanding != 'yes' && currOptions.cnc != 'yes' && currOptions.drilling != 'yes' && currOptions.sprayCoating != 'yes') ? (
                                <div className='materialSelectDetailsOption'>
                                    <p>No options available</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className='materialSelectDetailsThicknessesDiv'>
                        <p className='materialSelectDetailsTitle'>Available thicknesses:</p>
                        <div className='materialSelectThicknessesList'>
                            {thickArray.map((thick, index) => (
                                <div key={index} className='materialSelectThicknessesItem' style={{color: selectedThickness == index ? 'white' : 'black', background: selectedThickness == index ? '#3A86FF' : '#e4d5c7'}} onClick={() => thickBtnClicked(index)}>
                                    <p>{thick.thickValue}</p>
                                </div>
                            ))}
                        </div>
                        <div className='materialSelectThicknessesPrices'>
                            <div style={{display: 'flex', gap: '10px'}}>
                                <p className='materialSelectDetailsTitle'>Full sheet price:</p>
                                <p>£{currOptions.sheetPrice}</p>
                            </div>
                            <div style={{display: 'flex', gap: '10px'}}>
                                <p className='materialSelectDetailsTitle'>Sheet price with cutting:</p>
                                <p>£{currOptions.cutPrice}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default FullSheetMaterialElement