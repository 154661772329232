import React, {useState, useEffect, useRef, useLayoutEffect} from 'react'
import './selectOffcuts.css'
import { FiX, FiChevronDown, FiAlertTriangle, FiInfo } from "react-icons/fi";
import { LuFlipHorizontal, LuFlipVertical } from "react-icons/lu";


import { getBinPlacementsForBinWithOpenSelectOffcuts, getOffcutsForBinWithOpenSelectOffcuts, getOffcutsSelectionsForBin, updateOffcutsSelectionsForBin } from '../../new_order/orderConfig'

function SelectOffcuts(props) {
    const drawingPadding = 10;

    const offcutsPopupDrawingRef = useRef(null);
    //  get all the drawing info when the modal loads
    const [binPlacements, setBinPlacements] = useState([]);
    const [placedCuts, setPlacedCuts] = useState([]);
    const [offcuts, setOffcuts] = useState([]);
    const [offcutsSelections, setOffcutsSelections] = useState([]);
    const [offcutCutToggle, setOffcutCutToggle] = useState([]);
    const [offcutCutAvailable, setOffcutCutAvailable] = useState([]);
    const [offcutCutInputs, setOffcutCutInputs] = useState([]);
    const [offcutCutErrors, setOffcutCutErrors] = useState([]);

    const [isTablet, setIsTablet] = useState(false);
        const [isMobile, setIsMobile] = useState(false);
    
        useEffect(() => {
            // Function to update the state based on window width
            const handleResize = () => {
                if(window.innerWidth < 1220){
                    setIsTablet(true);
                    let length = 280 - drawingPadding*2;  // -10 din cauza la paddingul din card care e de 10
                    let width = 180;  // -10 din cauza la paddingul din card care e de 10
                    const dimensionsObj = {
                        length: length,
                        width: width
                    };
                    setSmallDrawingContainerSize(dimensionsObj);
                }else{
                    setIsTablet(false);
                    let length = 630 - drawingPadding*2;  // -10 din cauza la paddingul din card care e de 10
                    let width = 630 - drawingPadding*2;  // -10 din cauza la paddingul din card care e de 10
                    const dimensionsObj = {
                        length: length,
                        width: width
                    };
                    setSmallDrawingContainerSize(dimensionsObj);
                }
                setIsMobile(window.innerWidth <= 768);
            };
    
            // Initial check
            handleResize();
    
            // Add event listener for window resize
            window.addEventListener('resize', handleResize);
    
            // Cleanup function to remove the event listener
            return () => window.removeEventListener('resize', handleResize);
        }, []);

    useEffect(() => {
        const bins = getBinPlacementsForBinWithOpenSelectOffcuts();
        setBinPlacements(bins);

        const offcutsArray = getOffcutsForBinWithOpenSelectOffcuts();
        setOffcuts(offcutsArray);

        const newOffcutsSelections = {...getOffcutsSelectionsForBin()};
        setOffcutsSelections(newOffcutsSelections);
        console.log('OFFCUTS SELECTIONS:');
        console.log(newOffcutsSelections);

        const currBinOffcuts = newOffcutsSelections.offcuts;
        let cutToggleArray = [];
        for(let i = 0; i < currBinOffcuts.length; i++){
            if(currBinOffcuts[i].cut == true){
                cutToggleArray = [...cutToggleArray, true];
            }else{
                cutToggleArray = [...cutToggleArray, false];
            }
        }
        setOffcutCutToggle(cutToggleArray);

        let cutAvailabilityArray = [];
        for(let i = 0; i < currBinOffcuts.length; i++){
            const currOffcut = currBinOffcuts[i];
            let availability = {
                horizontal: false,
                vertical: false,
            }
            if(currOffcut.length >= 1000){
                availability.vertical = true;
            }
            if(currOffcut.width >= 1000){
                availability.horizontal = true;
            }
            cutAvailabilityArray = [...cutAvailabilityArray, availability];
        }
        setOffcutCutAvailable(cutAvailabilityArray);

        let offcutCutSideArray = [];
        for(let i = 0; i < currBinOffcuts.length; i++){
            const currOffcut = currBinOffcuts[i];
            const inputObj = {
                side: currOffcut.cutSide != null ? currOffcut.cutSide : null,
                inputLength: currOffcut.cutLength != null ? currOffcut.cutLength : null,
                inputWidth: currOffcut.cutWidth != null ? currOffcut.cutWidth : null,
                includeRemaining: currOffcut.includeRemaining,
                remainingLength: currOffcut.length2 != null ? currOffcut.length2 : null,
                remainingWidth: currOffcut.width2 != null ? currOffcut.width2 : null,
                remainingAvailable: currOffcut.remainingAvailable,
            }
            offcutCutSideArray = [...offcutCutSideArray, inputObj];
        }
        console.log('offcutCutSideArray:');
        console.log(offcutCutSideArray);
        setOffcutCutInputs(offcutCutSideArray);

        let cutErrors = [];
        for(let i = 0; i < currBinOffcuts.length; i++){
            cutErrors = [...cutErrors, null];
        }
        setOffcutCutErrors(cutErrors);

    }, [])

// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| DRAWING DISPLAY |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    //  get the small drawing container size
    const [smallDrawingContainerSize, setSmallDrawingContainerSize] = useState({length: 0, width: 0});
    // get the middleSection width and height in px
    useLayoutEffect(() => {
        const element = offcutsPopupDrawingRef.current;
        if (element) {
            // Get the size and position of the element
            const { width, height } = element.getBoundingClientRect();
            setSmallDrawingContainerSize(() => ({
                length: width - drawingPadding*2,
                width: height - drawingPadding*2,
            }));
            console.log('Middle section width:' + width);
            console.log('Middle section height:' + height);
        }
    }, []);

    const [scaledBins, setScaledBins] = useState(null);
    const [scaledCuts, setScaledCuts] = useState(null);
    const [scaledOffcuts, setScaledOffcuts] = useState(null);

    const[scaledOffcutsText, setScaledOffcutsText] = useState([]);

    //  calculate scaled bin rectangle, scaled cuts, scaled offcuts  
    useEffect(() => {
        let scaledBinsArray = [];
        let newScaledCuts = [];
        let newScaledOffcuts = [];
        if(binPlacements != null && binPlacements != undefined && offcuts != null && offcuts != undefined && smallDrawingContainerSize != null && smallDrawingContainerSize != undefined && smallDrawingContainerSize.length != null && smallDrawingContainerSize.width != null){
            //  calculate the scaled bin rectangle dimensions to get the scale percentages
            const fullPanelLength = parseInt(binPlacements.panelLength);
            const fullPanelWidth = parseInt(binPlacements.panelWidth);
            let scaledBinLength = null;
            let scaledBinWidth = null;
            if(fullPanelLength > fullPanelWidth){
                scaledBinLength = smallDrawingContainerSize?.length;
                const differencePercentage = fullPanelWidth / fullPanelLength;
                scaledBinWidth = scaledBinLength * differencePercentage;
            }else{
                scaledBinWidth = smallDrawingContainerSize?.width;
                const differencePercentage = fullPanelLength / fullPanelWidth;
                scaledBinLength = scaledBinWidth * differencePercentage;
            }
            console.log('-> scaledBinLength: ' + scaledBinLength);
            console.log('-> scaledBinWidth: ' + scaledBinWidth);
            const scaledBinObj = {length: scaledBinLength, width: scaledBinWidth};
            scaledBinsArray = [...scaledBinsArray, scaledBinObj];
            console.log('-> scaledBinsArray:');
            console.log(scaledBinsArray);

            //  calculate the position and dimensions for each scaled cut
            const cutsObj = binPlacements.cuts;
            console.log('-> placedCuts ');
            console.log(cutsObj);
            const scalePercentageLength = scaledBinLength / fullPanelLength;
            const scalePercentageWidth = scaledBinWidth / fullPanelWidth;
            let newScaledCutsObj = [];
            for(let j = 0; j < cutsObj?.length; j++){    //  for each cut individually
                const newScaledCut = {
                    length: parseInt(cutsObj[j]?.length) * scalePercentageLength,
                    width: parseInt(cutsObj[j]?.width) * scalePercentageWidth,
                    x: parseInt(cutsObj[j]?.x) * scalePercentageLength,
                    y: parseInt(cutsObj[j]?.y) * scalePercentageWidth,
                };
                newScaledCutsObj = [...newScaledCutsObj, newScaledCut];
            }
            newScaledCuts = [...newScaledCuts, newScaledCutsObj];
            console.log('-> newScaledCuts:');
            console.log(newScaledCuts);

            //  calculate the position and dimensions for each scaled offcut
            const offcutObj = offcuts?.offcuts;
            console.log('-> offcuts:'); 
            console.log(offcutObj);
            let newScaledOffutsObj = [];
            let newScaledOffcutsText = [];
            for(let j = 0; j < offcutObj?.length; j++){
                console.log('-> offcutObj[j]:');
                console.log(offcutObj[j]);
                const newScaledOffcut = {
                    length: parseInt(offcutObj[j]?.length) * scalePercentageLength,
                    width: parseInt(offcutObj[j]?.width) * scalePercentageWidth,
                    x: parseInt(offcutObj[j]?.x) * scalePercentageLength,
                    y: parseInt(offcutObj[j]?.y) * scalePercentageWidth,
                };
                newScaledOffutsObj = [...newScaledOffutsObj, newScaledOffcut];

                newScaledOffcutsText = [...newScaledOffcutsText, `${offcutObj[j].length} x ${offcutObj[j].width}`];
            }
            setScaledOffcutsText(newScaledOffcutsText);
            newScaledOffcuts = [...newScaledOffcuts, newScaledOffutsObj];
            console.log('-> newScaledOffcuts:');
            console.log(newScaledOffcuts);
        }
        setScaledBins([...scaledBinsArray]);
        setScaledCuts([...newScaledCuts]);
        setScaledOffcuts([...newScaledOffcuts]);
    }, [binPlacements, offcuts, smallDrawingContainerSize]);

// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| OFFCUTS SELECTION |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    const offcutSelectionToggle = (offcutIndex) =>{
        console.log(offcutsSelections.offcuts[offcutIndex]);
        const offcutElement = offcutsSelections.offcuts[offcutIndex];
        console.log(offcutElement.selected);
        if(offcutElement.selected == false){
            let offcutsSelectionsCopy = { ...offcutsSelections };
            let tempOffcutSelection = offcutsSelectionsCopy.offcuts[offcutIndex];
            tempOffcutSelection.selected = true;
            offcutsSelectionsCopy.offcuts[offcutIndex] = tempOffcutSelection;
            setOffcutsSelections(offcutsSelectionsCopy);
        }else{
            let offcutsSelectionsCopy = { ...offcutsSelections };
            let tempOffcutSelection = offcutsSelectionsCopy.offcuts[offcutIndex];
            tempOffcutSelection.selected = false;
            offcutsSelectionsCopy.offcuts[offcutIndex] = tempOffcutSelection;
            setOffcutsSelections(offcutsSelectionsCopy);
        }
    }

    const toggleOffcutCutExpand = (offcutIndex) =>{
        if(offcutCutToggle[offcutIndex] == false){
            let offcutsToggles = {...offcutCutToggle};
            offcutsToggles[offcutIndex] = true;
            setOffcutCutToggle(offcutsToggles);
        }else{
            let offcutsToggles = {...offcutCutToggle};
            offcutsToggles[offcutIndex] = false;
            setOffcutCutToggle(offcutsToggles);
        }
    }

    const offcutCutSideSelected = (side, offcutIndex) =>{
        if(side == 'horizontal' && offcutCutInputs[offcutIndex].side != 'horizontal'){
            let copyArray = {...offcutCutInputs};
            copyArray[offcutIndex].side = 'horizontal';
            //  set the input value to half of the width
            const newInputValue = Math.floor(parseInt(offcutsSelections.offcuts[offcutIndex].width) / 2);
            copyArray[offcutIndex].inputWidth = newInputValue;
            const newLengthValue = offcutsSelections.offcuts[offcutIndex].length;
            copyArray[offcutIndex].inputLength = newLengthValue;
            //  set the values for the remaining offcut
            const remainingWidth = parseInt(offcutsSelections.offcuts[offcutIndex].width) - newInputValue - 5;
            copyArray[offcutIndex].remainingWidth = remainingWidth;
            copyArray[offcutIndex].remainingLength = newLengthValue;
            copyArray[offcutIndex].remainingAvailable = true;
            setOffcutCutInputs(copyArray);
        }else if(side == 'vertical' && offcutCutInputs[offcutIndex].side != 'vertical'){
            let copyArray = {...offcutCutInputs};
            copyArray[offcutIndex].side = 'vertical';
            //  set the input value to half of the length
            const newInputValue = Math.floor(parseInt(offcutsSelections.offcuts[offcutIndex].length) / 2);
            copyArray[offcutIndex].inputLength = newInputValue;
            const newWidthValue = offcutsSelections.offcuts[offcutIndex].width;
            copyArray[offcutIndex].inputWidth = newWidthValue;
            //  set the values for the remaining offcut
            const remainingLength = parseInt(offcutsSelections.offcuts[offcutIndex].length) - newInputValue - 5;
            copyArray[offcutIndex].remainingWidth = newWidthValue;
            copyArray[offcutIndex].remainingLength = remainingLength;
            copyArray[offcutIndex].remainingAvailable = true;
            setOffcutCutInputs(copyArray);
        }
    }

    const offfcutCutInputChanged = (event, offcutIndex, inputSide) =>{
        const newValue = event.target.value;
        let copyArray = {...offcutCutInputs};
        const sanitizedValue = newValue.replace(/[^\d]/g, '');
        if(inputSide == 'length'){
            copyArray[offcutIndex].inputLength = sanitizedValue;
            if(sanitizedValue < 250 || sanitizedValue > (offcutsSelections.offcuts[offcutIndex].length - 65)){
                copyArray[offcutIndex].remainingAvailable = false;
                copyArray[offcutIndex].includeRemaining = false;
                let errorsArray = [...offcutCutErrors];
                errorsArray[offcutIndex] = `Min: 250, Max: ${offcutsSelections.offcuts[offcutIndex].length - 65}`;
                setOffcutCutErrors(errorsArray);
                setOffcutCutInputs(copyArray);
                return;
            }
            //  calculate the length of the remaining offcut
            const newRemainingLength = parseInt(offcutsSelections.offcuts[offcutIndex].length) - sanitizedValue - 5;
            copyArray[offcutIndex].remainingLength = newRemainingLength;
            copyArray[offcutIndex].remainingAvailable = true;
            if(newRemainingLength < 250){
                copyArray[offcutIndex].remainingAvailable = false;
                copyArray[offcutIndex].includeRemaining = false;
            }
            let errorsArray = [...offcutCutErrors];
            errorsArray[offcutIndex] = null;
            setOffcutCutErrors(errorsArray);
        }else if(inputSide == 'width'){
            copyArray[offcutIndex].inputWidth = sanitizedValue;
            if(sanitizedValue < 250 || sanitizedValue > (offcutsSelections.offcuts[offcutIndex].width - 65)){
                copyArray[offcutIndex].remainingAvailable = false;
                copyArray[offcutIndex].includeRemaining = false;
                let errorsArray = [...offcutCutErrors];
                errorsArray[offcutIndex] = `Min: 100, Max: ${offcutsSelections.offcuts[offcutIndex].width - 65}`;
                setOffcutCutErrors(errorsArray);
                setOffcutCutInputs(copyArray);
                return;
            }
            //  calculate the width of the remaining offcut
            const newRemainingWidth = parseInt(offcutsSelections.offcuts[offcutIndex].width) - sanitizedValue - 5;
            copyArray[offcutIndex].remainingWidth = newRemainingWidth;
            copyArray[offcutIndex].remainingAvailable = true;
            if(newRemainingWidth < 100){
                copyArray[offcutIndex].remainingAvailable = false;
                copyArray[offcutIndex].includeRemaining = false;
            }
            let errorsArray = [...offcutCutErrors];
            errorsArray[offcutIndex] = null;
            setOffcutCutErrors(errorsArray);
        }
        setOffcutCutInputs(copyArray);
    };

    const toggleRemainingInclude = (offcutIndex) =>{
        let copyArray = {...offcutCutInputs};
        copyArray[offcutIndex].includeRemaining = !copyArray[offcutIndex].includeRemaining;
        setOffcutCutInputs(copyArray);
    };

// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| UPDATE AND SAVE |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    const closeAndSave = () => {
        let offCutSelectionsCopy = [...offcutsSelections.offcuts];
        console.log('-> offcutsSelectionsCopy:');
        console.log(offCutSelectionsCopy);
        for(let i = 0; i < offCutSelectionsCopy.length; i++){
            let currOffcut = offCutSelectionsCopy[i];
            //  check if offcut has been cut
            if(offcutCutErrors[i] == null){
                if(offcutCutToggle[i] == true && (offcutCutInputs[i].side == 'horizontal' || offcutCutInputs[i].side == 'vertical')){
                    currOffcut.cut = true;
                    currOffcut.cutSide = offcutCutInputs[i].side;
                    currOffcut.cutLength = offcutCutInputs[i].inputLength;
                    currOffcut.cutWidth = offcutCutInputs[i].inputWidth;
                    currOffcut.length2 = offcutCutInputs[i].remainingLength;
                    currOffcut.width2 = offcutCutInputs[i].remainingWidth;
                    currOffcut.includeRemaining = offcutCutInputs[i].includeRemaining;
                    currOffcut.remainingAvailable = offcutCutInputs[i].remainingAvailable;
                    console.log('Offcut has been cut');
                }else{
                    currOffcut.cut = false;
                    currOffcut.cutSide = null;
                    currOffcut.cutLength = null;
                    currOffcut.cutWidth = null;
                    currOffcut.length2 = null;
                    currOffcut.width2 = null;
                    currOffcut.includeRemaining = false;
                    currOffcut.remainingAvailable = null;
                    console.log('Offcut has not been cut');
                }
            }else{
                currOffcut.selected = false;
                currOffcut.cut = false;
                currOffcut.cutSide = null;
                currOffcut.cutLength = null;
                currOffcut.cutWidth = null;
                currOffcut.length2 = null;
                currOffcut.width2 = null;
                currOffcut.includeRemaining = false;
                currOffcut.remainingAvailable = null;
                console.log('Offcut input has error so offcut has not been cut');
            }
            offCutSelectionsCopy[i] = currOffcut;
        }
        console.log('-> Sending offcuts selected to be saved:');
        console.log(offCutSelectionsCopy);
        updateOffcutsSelectionsForBin(offCutSelectionsCopy);
        closePopup();
    }

    const handleContainerClick = (e) => {
        // Check if the click occurred outside the container
        if (e.target.classList.contains('offcutsPopup')) {
            closeAndSave(); // Close the popup
        }
    };
    const closePopup = () =>{
        props.closeOffcutsPopup();
    }

    useEffect(() => {
        console.log('-> offcutCutInput has changed');
    }, [offcutCutInputs]);

    return (
        <div className='offcutsPopup' onClick={handleContainerClick}>
            <div className='offcutsPopupContainer'>
                <div className='offcutsPopupContent'>
                    <div className='offcutsPopupCloseBtn' onClick={() => closeAndSave()}>
                        <FiX />
                        <p>Close & save</p>
                    </div>
                    <div className='offcutsPopupControlContainer'>
                        <p style={{fontWeight: '600', fontSize: '18px'}}>Click on an offcut to select it</p>
                        <div className='panelDetails'>
                            <p>{binPlacements.product_code} | {binPlacements.brand}</p>
                            <p>{binPlacements.decor_name} | {binPlacements.material_type}</p>
                            <p>{binPlacements.panelLength} x {binPlacements.panelWidth} x {binPlacements.thickness} mm</p>
                            <p style={{paddingTop: '10px'}}>These offcuts will be included in your order delivery:</p>
                        </div>
                        <div className='selectedOffcutsList'>
                            {offcutsSelections.offcuts?.map((offcut, offcutIndex) => (
                                offcut.selected == true ? (
                                    <div key={offcutIndex} className='selectedOffcutContainer'>
                                        <div className='selectedOffcutTop'>
                                            <p>Offcut:</p>
                                            {offcutCutInputs[offcutIndex].includeRemaining == false ? (
                                                offcutCutToggle[offcutIndex] === true ? (
                                                    <p>{offcutCutInputs[offcutIndex].side != null ? offcutCutInputs[offcutIndex].inputLength : offcut.length} x {offcutCutInputs[offcutIndex].side != null ? offcutCutInputs[offcutIndex].inputWidth : offcut.width}</p>
                                                ) : (
                                                    <p>{offcut.length} x {offcut.width}</p>
                                                )
                                            ) : (
                                                offcutCutToggle[offcutIndex] === true ? (
                                                    <p style={{fontSize: 12}}>{offcutCutInputs[offcutIndex].inputLength} x {offcutCutInputs[offcutIndex].inputWidth} & {offcutCutInputs[offcutIndex].remainingLength} x {offcutCutInputs[offcutIndex].remainingWidth}</p>
                                                ) : (
                                                    <p>{offcut.length} x {offcut.width}</p>
                                                )
                                            )}
                                            {offcutCutToggle[offcutIndex] === true ? (
                                                <div className='selectedOffcutToggleBtn' style={{background: '#e4d5c7'}} onClick={() => toggleOffcutCutExpand(offcutIndex)}>
                                                    <p>Remove cut</p>
                                                </div>
                                            ) : (
                                                <div className='selectedOffcutToggleBtn' style={{background: '#3a86ff', color: 'white'}} onClick={() => toggleOffcutCutExpand(offcutIndex)}>
                                                    <p>Cut</p>
                                                </div>
                                            )}
                                        </div>
                                        {offcutCutToggle[offcutIndex] === true ? (
                                            <div className='selectedOffcutExtended'>
                                                <div className='selectedOffcutLineCutSelection'>
                                                    <p>Cut:</p>
                                                    <div className='selectedOffcutLineOptions'>
                                                        <div className='selectedOffcutLineBtn' 
                                                            style={{background: offcutCutInputs[offcutIndex].side === 'horizontal' ? '#3a86ff' : '#f8f6f5', color: offcutCutInputs[offcutIndex].side === 'horizontal' ? 'white' : 'black', opacity: offcutCutAvailable[offcutIndex].horizontal ? 1 : 0.5}}
                                                            onClick={() => offcutCutAvailable[offcutIndex].horizontal ? offcutCutSideSelected('horizontal', offcutIndex) : null}
                                                        >
                                                            <LuFlipVertical/>
                                                            <p>Horizontal</p>
                                                        </div>
                                                        <div className='selectedOffcutLineBtn' 
                                                            style={{background: offcutCutInputs[offcutIndex].side === 'vertical' ? '#3a86ff' : '#f8f6f5', color: offcutCutInputs[offcutIndex].side === 'vertical' ? 'white' : 'black', opacity: offcutCutAvailable[offcutIndex].horizontal ? 1 : 0.5}}
                                                            onClick={() => offcutCutAvailable[offcutIndex].vertical ? offcutCutSideSelected('vertical', offcutIndex) : null}    
                                                        >
                                                            <LuFlipHorizontal/>
                                                            <p>Vertical</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {offcutCutInputs[offcutIndex].side === 'horizontal' || offcutCutInputs[offcutIndex].side === 'vertical' ? (
                                                    <>
                                                        <div className='selectedOffcutCutDimensionsDiv'>
                                                            <p>New dimensions:</p>
                                                            <div className='selectedOffcutCutInputs'>
                                                                {offcutCutInputs[offcutIndex].side === 'horizontal' ? (
                                                                    <div className='selectedOffcutCutInputDiv'>
                                                                        <p>{offcut.length}</p>
                                                                        <p>x</p>
                                                                        <input 
                                                                            value={offcutCutInputs[offcutIndex]?.inputWidth?.toString()}
                                                                            onChange={(event) => offfcutCutInputChanged(event, offcutIndex, 'width')}
                                                                            style={offcutCutErrors[offcutIndex] !== null ? {border: '1px solid #fb0429'} : {border: '1px solid #ccc'}}
                                                                            className='selectedOffcutInput'
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div className='selectedOffcutCutInputDiv'>
                                                                        <input 
                                                                            value={offcutCutInputs[offcutIndex]?.inputLength?.toString()}
                                                                            onChange={(event) => offfcutCutInputChanged(event, offcutIndex, 'length')}
                                                                            style={offcutCutErrors[offcutIndex] !== null ? {border: '1px solid #fb0429'} : {border: '1px solid #ccc'}}
                                                                            className='selectedOffcutInput'
                                                                        />
                                                                        <p>x</p>
                                                                        <p>{offcut.width}</p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {offcutCutErrors[offcutIndex] !== null ? (
                                                            <div className='selectedOffcutAlertDiv'>
                                                                <div className='selectedOffcutAlert'>
                                                                    <FiAlertTriangle className='selectedOffcutAlertIcon'/>
                                                                    <p className='selectedOffcutAlertText'>Offcut will not be included in the order!</p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className='selectedOffcutIncludeRemainingDiv'>
                                                                <div className='selectedOffcutIncludeRemainingBtn' 
                                                                    style={offcutCutInputs[offcutIndex].includeRemaining === false ? {background: '#3a86ff', color: 'white'} : {background: '#f8f6f5', color: 'black'}}
                                                                    onClick={() => toggleRemainingInclude(offcutIndex)}    
                                                                >
                                                                    <p>{offcutCutInputs[offcutIndex].includeRemaining === false ? 'Include' : 'Remove'} remaining offcut: {offcutCutInputs[offcutIndex]?.remainingLength} x {offcutCutInputs[offcutIndex]?.remainingWidth}</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                ) : null}
                                            </div>
                                        ) : null}
                                    </div>
                                ) : null
                            ))}
                        </div>
                    </div>
                    <div className='offcutsPopupDrawingContainer'>
                        <div className='offcutsPopupDrawing' ref={offcutsPopupDrawingRef}>
                            <svg width={smallDrawingContainerSize?.length + drawingPadding*2} height={smallDrawingContainerSize?.width + drawingPadding*2}>
                                <defs>
                                    <pattern id="dashPattern" patternUnits="userSpaceOnUse" width={10} height={10}>
                                        <rect x={0} y={0} width="100%" height="100%" fill="hsla(0, 0%, 96%, 1)" />
                                        <path d="M0 10h20z" strokeWidth={10} stroke="hsla(0, 0%, 100%, 1)" fill="none" />
                                    </pattern>
                                </defs>
                                {scaledBins?.length !== 0 && scaledCuts?.length !== 0 && scaledOffcuts?.length !== 0 && offcutsSelections?.length !== 0 ? (
                                    <>
                                        <rect x={((smallDrawingContainerSize?.length + drawingPadding*2) - (scaledBins[0]?.length || 0))/2} y={((smallDrawingContainerSize?.width + drawingPadding*2) - scaledBins[0]?.width)/2} width={(scaledBins[0]?.length || 0)} height={scaledBins[0]?.width} fill="url(#dashPattern)" stroke={"#247156"} strokeWidth={1}/>
                                        {scaledOffcuts[0].length > 0 && scaledOffcuts[0].map((offcut, offcutIndex) => (
                                            <React.Fragment key={`smallScaledOffcutFragment0 ${offcutIndex}`}>
                                                {offcutsSelections.offcuts[offcutIndex].selected == true ? (
                                                    offcut.x && offcut.y && offcut.length && offcut.width ? (
                                                        <rect style={{cursor: 'pointer'}} key={`smallScaledOffcut0${offcutIndex}`} x={((smallDrawingContainerSize?.length + drawingPadding*2) - scaledBins[0].length)/2 + offcut.x} y={((smallDrawingContainerSize?.width + drawingPadding*2) - scaledBins[0].width)/2 + offcut.y} width={offcut.length} height={offcut.width} fill='rgba(153, 217, 140, 0.5)' stroke={"#247156"} strokeWidth={1} onClick={() => offcutSelectionToggle(offcutIndex)} />
                                                    ) : null
                                                ) : (
                                                    offcut.x && offcut.y && offcut.length && offcut.width ? (
                                                        <rect style={{cursor: 'pointer'}} key={`smallScaledOffcut0${offcutIndex}`} x={((smallDrawingContainerSize?.length + drawingPadding*2) - scaledBins[0].length)/2 + offcut.x} y={((smallDrawingContainerSize?.width + drawingPadding*2) - scaledBins[0].width)/2 + offcut.y} width={offcut.length} height={offcut.width} fill='transparent' stroke={"#247156"} strokeWidth={1} onClick={() => offcutSelectionToggle(offcutIndex)} />
                                                    ) : null
                                                )}
                                                <text style={{userSelect: 'none', cursor: 'pointer'}} key={`smallScaledOffcutText0${offcutIndex}`} fill="black" fontWeight="400" fontSize="10" x={((smallDrawingContainerSize.length + drawingPadding*2) - scaledBins[0].length)/2 + offcut.x + (offcut.length /2)} y={((smallDrawingContainerSize.width + drawingPadding*2) - scaledBins[0].width)/2 + offcut.y + (offcut.width / 2) - 5} textAnchor="middle" onClick={() => offcutSelectionToggle(offcutIndex)}>Offcut</text>
                                                <text style={{userSelect: 'none', cursor: 'pointer'}} key={`smallScaledOffcutText20${offcutIndex}`} fill="black" fontWeight="400" fontSize="10" x={((smallDrawingContainerSize.length + drawingPadding*2) - scaledBins[0].length)/2 + offcut.x + (offcut.length /2)} y={((smallDrawingContainerSize.width + drawingPadding*2) - scaledBins[0].width)/2 + offcut.y + (offcut.width / 2) + 5} textAnchor="middle" onClick={() => offcutSelectionToggle(offcutIndex)}>{scaledOffcutsText[offcutIndex]}</text>
                                            </React.Fragment>
                                        ))}
                                        {scaledCuts[0].map((cut, cutIndex) => (
                                            <React.Fragment key={`smallScaledOffcutFragment02${cutIndex}`}>
                                                cut.x && cut.y && cut.length && cut.width ? (
                                                    <rect key={`smallScaledCut0${cutIndex}`} x={((smallDrawingContainerSize.length + drawingPadding*2) - scaledBins[0].length)/2 + cut.x + 1} y={((smallDrawingContainerSize.width + drawingPadding*2) - scaledBins[0].width)/2 + cut.y + 1} width={cut.length} height={cut.width} fill="#99d98c" stroke={"#247156"} strokeWidth={1}/>
                                                    <text style={{userSelect: 'none', cursor: 'pointer'}} key={`smallScaledOffcutText20${cutIndex}`} fill="black" fontWeight="400" fontSize="10" x={((smallDrawingContainerSize.length + drawingPadding*2) - scaledBins[0].length)/2 + cut.x + (cut.length /2)} y={((smallDrawingContainerSize.width + drawingPadding*2) - scaledBins[0].width)/2 + cut.y + (cut.width / 2) + 5} textAnchor="middle">{cutIndex + 1}</text>
                                                ) : null    
                                            </React.Fragment>
                                        ))}
                                    </>
                                ) : null}
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectOffcuts